import styles from './chat-popover.module.scss';
import { InputAdornment, Typography } from "@material-ui/core";
import { getIcon } from "app/dashboard/icons";
import { Badge, IconButton, Message, Popover, TextField } from "components";
import chatBubble from 'assets/images/icon/chatbubble.png';
import closeIcon from 'assets/images/icon/close.svg';
import { useState } from "react";
import AuthServices from 'api/services/auth-services';
import { useEffect } from 'react';

const ChatPopover = ({ id, refreshChat, groupedChats, m_obj_id }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [draft, setDraft] = useState("");
    // const { state: { allMasterList, selectedSenior } } = useContext(GlobalContext);

    const scrollToBottom = () => {
        document?.getElementsByClassName("chat-section")[0]?.children[document?.getElementsByClassName("chat-section")[0]?.children.length - 1]?.lastChild.scrollIntoView({
            behavior: 'smooth',
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setDraft(event.target.value)
    }

    const sendMessage = async () => {
        if (!draft) {
            return
        }
        setDraft('')
        await AuthServices.sendChat({
            message: draft,
            master_id: m_obj_id
        })
        refreshChat()
    }

    const open = Boolean(anchorEl);

    useEffect(() => {
        scrollToBottom()
    }, [groupedChats])

    return (
        <div className={`${styles.chatPopover}`}>
            <IconButton className={`${styles.chatBubble}`} onClick={handleClick}>
                <img src={chatBubble} alt="chat" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                TransitionProps={{ onEntered: () => scrollToBottom() }}
            >
                <div className={`${styles.chat} d-flex flex-column `}>
                    <div className={`${styles.chatHeader} d-flex flex-row align-items-center justify-content-between p-2`}>
                        <div className={`d-flex align-items-center`}>
                            <div className={`${styles.chatDiv} d-flex flex-row align-items-center justify-content-center mr-xl-2`}>
                                <img src={chatBubble} alt="Chat" />
                            </div>
                            <Typography className={`txt-grey-lt txt-xmd`}>Family Chat</Typography>
                        </div>
                        <img src={closeIcon} alt="close" className={`${styles.closeIcon} cursor pr-2`} onClick={handlePopOverClose} />
                    </div>
                    <div className={`${styles.chatBody} chat-section`}>
                        {
                            Object.keys(groupedChats)?.length > 0 && Object.keys(groupedChats)?.map((key, index) => {
                                return (
                                    <div key={index}>
                                        <div className={`d-flex justify-content-center mt-xl-2`}>
                                            <Badge className={`${styles.dateBadge} px-2 txt-md`} key={index}>{key}</Badge>
                                        </div>
                                        {
                                            groupedChats[key]?.map(({ member_id, message, profile, date_time, by }, index) => {
                                                return <Message key={index} currentUser={member_id === id} message={message} profile={profile} date_time={date_time}
                                                    by={by} />
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={draft}
                            onChange={handleChange}
                            onKeyDown={(event) => event.key === 'Enter' && sendMessage()}
                            chatAdornment
                            className={'chat-input'}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={sendMessage}>
                                            {getIcon("Send", "#00B89F", "sendIcon")}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default ChatPopover;