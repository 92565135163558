import AuthServices from "api/services/auth-services"
import closeIcon from 'assets/images/icon/close.svg';
import { Dialog, DialogContent, Snackbar } from "components"
import { useEffect, useState } from "react";
import { getIcon } from "app/dashboard/icons";
import styles from "../live-view.module.scss";

const LiveVideo = ({ open, handleClose, seniorId }) => {

    const [url, setUrl] = useState('');
    const [feed, setFeed] = useState('');
    const [toggle, setToggle] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const getVideoLink = async () => {
        try {
            const { data: { videos } } = await AuthServices.getVideo(seniorId)
            videos?.forEach((senior) => {
                if (senior[seniorId]) {
                    if (senior[seniorId]?.live_video?.startsWith('rtsp')) {
                        setUrl(senior[seniorId]?.live_video)
                        setFeed(`https://api.ntelcare.com/video_feed?url=${senior[seniorId]?.live_video}`)
                        handleClose()
                    } else {
                        handleClose()
                    }
                }
            })
        } catch (err) {
            const { data } = err || {}
            const { message } = data || "member not found";
            setShowSnackBar(true);
            setSnackBarContent({ message, class: "error" })

        }
    }

    useEffect(() => {
        getVideoLink()
    }, [])

    return (
        <div className={`${styles.liveView}`}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='xl'
                PaperProps={{
                    classes: { root: `${styles.liveViewPaper} ${toggle ? styles.fullScreen : styles.screen}` }
                }}
            >
                <DialogContent className={`${styles.dialogContentLive}`} style={{ backgroundColor: 'transparent', position: 'relative', padding: 0 }}>
                    <img src={closeIcon} alt="close" className={`${styles.closeIco} cursor`} onClick={handleClose} />
                    <div className={styles.imgContainer}>
                        <img src={`${feed}`} alt='Live Video' className={`${styles.feedImage}`} />
                        <div onClick={() => setToggle(prev => !prev)} className={`${styles.hoverPlayer}`}>
                            {getIcon('Full Screen', '#FFF', styles.fullScreenIcon)}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </div>
    )
}

export default LiveVideo