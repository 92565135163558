import { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { PreLogIn, PostLogIn } from 'layouts';
import { PrivateRoute, PublicRoute } from 'routes';

import Login from 'app/login/login';
import ForgotPassword from 'app/forget-password/forget-password';

import Dashboard from 'app/dashboard/dashboard';
import { GlobalContext, actions } from 'context';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthServices from 'api/services/auth-services';


const App = () => {

    const { state: { showLoader, isLoggedIn }, dispatch } = useContext(GlobalContext);

    useEffect(() => {
        validateLogIn()
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            getRelationsDetails()
        }
    }, [isLoggedIn])

    const getRelationsDetails = async () => {
        const { data } = await AuthServices.getRelations()
        storeHandler(actions.SET_RELATIONS, [...data]);
    }

    const validateLogIn = async () => {
        let token = localStorage.getItem('authToken');
        storeHandler(actions.LOG_IN, !!token);
    }
    const storeHandler = (type, payload) => dispatch({ type, payload })

    const Help = () => {
        return (
            <>
                <div className='txt-white'>

                    Build Version: <span className='txt-primary'> {process.env.REACT_APP_VERSION} </span> <br /> <br />
                    Date:  <span className='txt-primary'>{process.env.REACT_APP_BUILD_DATE}</span>
                </div>
            </>
        )
    }

    return (
        <div>
            {showLoader && <div className='loader'>
                <CircularProgress />
            </div>}
            <Switch>
                <PublicRoute path="/help" component={Help} layout={PreLogIn} />
                <PublicRoute path="/login" component={Login} layout={PreLogIn} />
                <PublicRoute path="/forgot-password" component={ForgotPassword} layout={PreLogIn} />
                <PrivateRoute path="/" component={Dashboard} layout={PostLogIn} />
            </Switch>
        </div>
    )
}

export default App