import styles from "./message.module.scss";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import avatar from 'assets/images/icon/avatar.svg';

const Message = ({ currentUser, message, profile, date_time,by}) => {
    return (
        <div className={`${styles.messageContainer} d-flex flex-column ${currentUser && 'align-items-end'}`}>
            {by &&  <Typography className={`txt-xs txt-black-lt`}>{by}</Typography>}
            <div className={`${styles.contentContainer} d-flex justify-content-between ${currentUser ? "flex-row" : "flex-row-reverse"}`}>
                <div className={`d-flex align-items-center`}>
                    <img src={profile || avatar} alt="Logo" className={`${styles.avatarImage}`} />
                </div>
                <div className={`${styles.content} ${currentUser ? styles.currentUser : styles.otherUser}`}>
                    <Typography className={`${styles.messageText} txt-smd`}>{message}</Typography>
                </div>
            </div>
            {!!date_time && <Typography className={`txt-xs txt-black-lt`}>{format(new Date(date_time), 'hh:mm aaa')}</Typography>}
        </div>
    )
}

export default Message;