import { useState, useContext } from 'react';
import { Box, TextField, Button, Snackbar } from 'components';
import { useHistory } from 'react-router-dom';
import BackIcon from 'assets/images/icon/back-icon.svg';
import { Link } from 'react-router-dom';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';

const PASSWORD_SUCCESSFULLY_CHANGED = 'Password changed successfully!';

const ChangePassword = ({ email }) => {

    const { dispatch } = useContext(GlobalContext);

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [invalidPassword, setInvalidPassword] = useState(false)
    const [passwordMissmatch, setPasswordMissmatch] = useState(false)

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    const history = useHistory();

    const validatePassword = () => {
        if (password === confirmPassword && password.length >= 8) {
            resetPassword()
        }
    }

    const resetPassword = async () => {

        const formData = new FormData();
        formData.append('email', email)
        formData.append('password', password)
        formData.append('confirm_password', confirmPassword)
        storeHandler(actions.SHOW_LOADER, true)
        setShowSnackBar(false)

        try {
            const { message } = await AuthServices.resetPassword(formData);
                const data = { message, class: 'success' }
                setShowSnackBar(true)
                setSnackBarContent({ ...data })
                setTimeout(() => { history.push('/login') }, 2000)
            
            storeHandler(actions.SHOW_LOADER, false)

        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
            const data = { message: 'Please try again', class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
        }
    }

    const passwordValidation = () => {
        setInvalidPassword(!!password && password.length < 8)
        setPasswordMissmatch(!!confirmPassword && password !== confirmPassword)
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })


    return (
        <>
            <div>
                <Box textAlign="center" className='txt-lg txt-bold mb-3 d-flex justify-content-between txt-primary'><Link to="/login"><img src={BackIcon} alt="back" className='cursor' /></Link><span className='flex-grow-1'>Change Password</span></Box>
                <form>
                    <TextField label="Enter New Password" variant="filled" fullWidth className={'mb-3'} passwordfield={true} onChange={(event) => setPassword(event.target.value)} onBlur={passwordValidation}
                        helperText={invalidPassword && 'Enter min 8 character'} />
                    <TextField label="Confirm Password" variant="filled" withoutAdornment={false} fullWidth className={'mb-3'} passwordfield={true} onChange={(event) => setConfirmPassword(event.target.value)} onBlur={passwordValidation}
                        helperText={passwordMissmatch && 'Password Mismatch'} />
                    <Button variant="contained" color="primary" className={'btn-lg'} disabled={!password || !confirmPassword} onClick={validatePassword}>
                        Confirm
                    </Button>
                </form>

            </div>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </>
    )
}

export default ChangePassword;