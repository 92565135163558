import { useEffect, useState, useContext, useRef } from 'react';
import styles from './members.module.scss';
import avatar from 'assets/images/icon/default.svg';
import badge from 'assets/images/icon/badge.svg';
import addIcon from 'assets/images/icon/add-icon.svg';
import { ReactComponent as PhoneIcon } from "assets/images/icon/phone-icon.svg";
import { ReactComponent as EmailIcon } from "assets/images/icon/email.svg";

// import addIcon from 'assets/images/icon/add-icon.svg';


import AddMember from 'app/dashboard/add-members/add-members';
import EditMember from 'app/dashboard/edit-member/edit-member';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { ConfirmDialog, Snackbar } from 'components';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';


const MembersCard = ({ info, refreshList, isExecutive, m_obj_id }) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [promptText, setPromptText] = useState()

    const handleClose = (event, reason, text) => {
        setOpenDialog(false);
        refreshList(true)
        if (text) {
            setPromptText(text)
            setOpenPopup(true)
        }
    }

    const { fname, lname, email, mobile, profile, permission, executive, last_login } = info || {};
    const { chat, live_video, view_video, cen } = permission || {}
    const date = last_login ? moment(new Date(last_login).toLocaleString()).format("MMM DD YYYY h:mm:ss A") : null;
    const onConfirm = () => {
        setOpenPopup(false);
    }

    return (
        <>
            <div className={`${styles.membersCard} p-2 mr-3 mb-3`} onClick={() => { isExecutive && setOpenDialog(true) }}>
                <div className='d-flex'>
                    <div className={`${styles.avatar}`}>
                        <img src={profile || avatar} alt={'Profile'} />
                        {executive && <div className={`${styles.badge} d-flex align-items-center justify-content-center`}>
                            <img src={badge} alt="" />
                        </div>}
                    </div>
                    <div className='pl-3 flex-grow-1'>
                        <p className='txt-md m-0 d-flex justify-content-between align-items-start mb-3'>
                            <div className='flex-grow-1'>
                                <div className={`${styles.infoName} txt-primary`}><span >{fname} {lname}</span></div>
                                <div className='txt-xs txt-grey-lt m-0'>{date}</div>
                            </div>
                        </p>
                        <Tooltip title={email}>
                            <p className={`txt-xs txt-grey-lt mb-1 mt-2 d-flex align-items-center`}><EmailIcon className={`${styles.cardPhoneIcon}`} /><span className={`${styles.info}`}>{email}</span></p>
                        </Tooltip>
                        <p className='txt-xs txt-grey-lt mb-1 mt-0 d-flex align-items-center'><PhoneIcon className={`${styles.cardPhoneIcon}`} /> <span className={`${styles.info}`}>{mobile}</span></p>
                    </div>
                </div>

                <div className={`${styles.permission} mt-3`}>
                    <p className='text-center mt-2 txt-xs txt-grey-lt'>Permissions</p>
                    <div className='d-flex justify-content-center'>
                        <div className={`${styles.permissionItems} ${live_video ? styles.active : ''} mr-3 txt-xs d-flex justify-content-center align-items-center`}>
                            Live Video
                        </div>
                        <div className={`${styles.permissionItems} ${view_video ? styles.active : ''} mr-3 txt-xs d-flex justify-content-center align-items-center`}>
                            View Motion Clips
                        </div>
                        <div className={`${styles.permissionItems} ${chat ? styles.active : ''} mr-3 txt-xs d-flex justify-content-center align-items-center`}>
                            Chat
                        </div>
                        <div className={`${styles.permissionItems} ${cen ? styles.active : ''} mr-0 txt-xs d-flex justify-content-center align-items-center`}>
                            CEN
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <EditMember open={openDialog} handleClose={handleClose} memberInfo={info} m_obj_id={m_obj_id} />}
            <ConfirmDialog isPrompt={true} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={promptText} />
        </>

    )
}
const Members = ({seniorId}) => {
    
    const { state: { userData, selectedMasterData: { m_obj_id } }, dispatch } = useContext(GlobalContext);
    const { executive } = userData || {}

    const memberLimit = useRef('')

    const [openDialog, setOpenDialog] = useState(false);
    const [membersList, setMembersList] = useState([])
    const [refreshList, setRefreshList] = useState(false)
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const [openPopup, setOpenPopup] = useState(false);
    const [promptText, setPromptText] = useState()

    const handleOpenDialog = () => {

        if (![null, undefined, ''].includes(memberLimit.current) && Number(memberLimit.current) <= membersList?.length) {
            setShowSnackBar(true)
            setSnackBarContent({ message: 'Maximum Member Limit exceeded!', class: 'error' })
        } else {
            setOpenDialog(true)
        }

    }

    const handleClose = (event, reason, text) => {
        setOpenDialog(false);
        if (text) {
            setPromptText(text)
            setOpenPopup(true)
        }
    }

    useEffect(() => {
        !openDialog && getMembers()
    }, [openDialog])

    useEffect(() => {
        if (refreshList) {
            getMembers()
        } else {
            setRefreshList(false)
        }
    }, [refreshList])

    const getMembers = async () => {
        if (!m_obj_id) return

        storeHandler(actions.SHOW_LOADER, true)

        const { members, limit_members } = await AuthServices.getMembers(m_obj_id)
        memberLimit.current = limit_members
        setMembersList(members)
        storeHandler(actions.SHOW_LOADER, false)
        setRefreshList(false)

    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const onConfirm = () => {
        setOpenPopup(false);
    }

    return (
        <div>
            {executive && <div className='d-flex justify-content-lg-end justify-content-center border-bottom pb-3 px-5 mb-5'>
                <span onClick={handleOpenDialog} className='d-flex align-items-center cursor'> <img src={addIcon} alt="add" className={`${styles.addIcon} mr-2`} /> Add Member</span>
            </div>}
            <div className={`${styles.membersWrapper} p-lg-3 p-2 d-flex flex-wrap justify-content-md-start justify-content-center`}>
                {!!membersList?.length && membersList.map((item, index) => <MembersCard key={index} info={item} refreshList={setRefreshList} isExecutive={executive} m_obj_id={m_obj_id} senior_id={seniorId} />)}
            </div>
            {openDialog && <AddMember open={openDialog} handleClose={handleClose} obj_id={m_obj_id} senior_id={seniorId}  />}
            <ConfirmDialog isPrompt={true} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={promptText} />
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </div>
    )
}

export default Members