import { useState, useContext, useEffect, useRef } from 'react';
import { TextField, Dialog, DialogContent, Button, Select, MenuItem, DatePicker, Switch, Snackbar } from 'components';
import avatar from 'assets/images/icon/default.svg';
import submitIcon from 'assets/images/icon/submit-icon.svg';
import deleteIcon from 'assets/images/icon/delete-icon.svg';

import styles from './edit-member.module.scss';
import { GlobalContext, actions } from 'context';
import AuthServices from 'api/services/auth-services';
import { isEmailValid, isName, isNumber, isValidName, isValidRoom, isZip, mobileFormatter } from 'utils/validator';
import { ConfirmDialog } from 'components';
import closeIcon from 'assets/images/icon/close.svg';
import FormHelperText from '@material-ui/core/FormHelperText';

const errorMessages = {
    fname: 'Enter First Name',
    lname: 'Enter Last Name',
    email: 'Enter Email',
    mobile: 'Enter Phone Number',
    address: 'Enter Address',
    gender: 'Select Gender',
    // dob: 'Select Date of Birth',
    relation_id: 'Select Relationship',
    zipcode: 'Enter Zipcode',
    city: "Enter City",
    state: "Enter State",
    country: "Select Country",
}

const switchFields = ['executive', 'view_video', 'live_video', 'chat', 'cen']


const EditMember = ({ open, handleClose, memberInfo, isLoggedInUser, m_obj_id, senior_id }) => {
    const { state: { countryList, relationList, userData }, dispatch } = useContext(GlobalContext);
    const dateOfBirth = memberInfo?.dob?.includes('GMT') ? new Date(memberInfo?.dob).toISOString().split('T')[0] : memberInfo?.dob
    const [memberDetails, setMemberDetails] = useState({ ...memberInfo, ...memberInfo?.permission, dob: dateOfBirth });
    const memberDetailsRef = useRef({ ...memberInfo, ...memberInfo?.permission, dob: dateOfBirth })
    const [invalidForm, setInvalidForm] = useState(false);

    const [imageFile, setImageFile] = useState();
    const [image, setImage] = useState(memberInfo?.profile);
    const [invalidFileSize, setInvalidFileSize] = useState(false);

    const [mobile, setMobile] = useState('');
    const [invalidMobile, setInvalidMobile] = useState(false);
    const [invalidZip, setInvalidZip] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [minLengthInvalid, setMinLengthInvalid] = useState(false)
    const [openPopup, setOpenPopup] = useState(false);
    const [validateEmail, setValidateEmail] = useState({ status: false, data: { ...memberInfo, ...memberInfo?.permission, dob: dateOfBirth } })
    const [validateSenior, setValidateSenior] = useState({ status: false, list: [] })
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const [seniorList, setSeniorList] = useState([])
    const [uploaded, setUploaded] = useState(false)
    const [profileImgFile, setFile] = useState({})
    const [profileImgName, setFileName] = useState({})

    const snackbarNotification = (data) => {
        setSnackBarContent(data)
        setShowSnackBar(true)
    }

    const { fname, lname, email, address, zipcode, gender, age, relation, relation_id, dob, profile, city, state, country, executive, view_video, live_video, chat, cen, id } = memberDetails || {}

    const executivePrevilage = { view_video: true, live_video: true, chat: true, cen: true }

    const revokePrevilage = { ...memberInfo?.permission }

    const { id: userId } = userData

    useEffect(() => {
        updateExecutivePrevillage(executive)
    }, [executive])

    useEffect(() => {
        setMobile(mobileFormatter(memberInfo?.mobile));
    }, []);

    const updateMemberDetails = (event) => {
        const { name, value, checked } = event.target;

        if ((name === 'fname' || name === 'lname') && !isValidName(value)) return
        if ((name === 'room_no') && !isValidRoom(value)) return
        if (name === 'zipcode') zipValidator(value)
        if (name === 'email' && value.length > 50) return

        switchFields.includes(name) ?
            setMemberDetails({ ...memberDetails, [name]: checked }) :
            setMemberDetails({ ...memberDetails, [name]: value })
    }

    const updateExecutivePrevillage = (isExecutive) => {
        let revPre = userId === memberDetails?.['id'] ? revokePrevilage : {}
        setMemberDetails({ ...memberDetails, ...(isExecutive ? executivePrevilage : revPre) })
    }

    const handleDateChange = (date) => {
        setMemberDetails({ ...memberDetails, dob: date })
    }

    const mobileValidator = (value) => {
        let checkValue = value?.split('.')?.join('');
        if (checkValue?.length >= 0 && checkValue?.length < 10) setInvalidMobile(true);
        else setInvalidMobile(false);
    }

    const handleChangeMobile = (event) => {
        const mobileValue = event.target.value?.split('.')?.join('');
        if (isNumber(mobileValue)) {
            // setMemberDetails({ ...memberDetails, mobile: mobileFormatter(mobileValue)});
            setMobile(mobileFormatter(mobileValue))
            setMemberDetails({ ...memberDetails, mobile: mobileValue })
            mobileValidator(mobileValue)
        } else {
            // setMemberDetails({ ...memberDetails, mobile: mobileValue.length > 0 ? mobile : ''});
            setMobile(mobileValue.length > 0 ? mobile : '')
            mobileValidator(mobileValue.length > 0 ? mobile : '')
        }
    }

    const saveDetails = () => {
        let isInvalidForm = false;
        let requiredFields = Object.keys(errorMessages);
        requiredFields.forEach(item => {
            // if(item === 'dob'){
            //     isInvalidForm = false
            //     setInvalidForm(false);
            // }else 
            if (!memberDetails[item]) {
                isInvalidForm = true
                setInvalidForm(true);
                return
            }
        })

        if (!isInvalidForm && !invalidFileSize && !invalidEmail && (!invalidMobile && mobile !== '') && !invalidZip) {
            const member = memberDetailsRef?.current || {}
            let isDataChanged = false
            for (let key in member) {
                let memberKey = key == 'sex' ? 'gender' : key
                if (!['executive', 'age', 'permission', 'master_id', 'view_video', 'live_video', 'chat', 'cen'].includes(key)) {
                    console.log(memberKey,"check");
                    if (memberDetails[memberKey] != member[key]) {
                        if (key === 'dob') {
                            const dateOfBirth = member[key]?.includes('GMT') ? new Date(member[key]).toISOString().split('T')[0] : member[key]
                            if (memberDetails[memberKey] == dateOfBirth) {
                                continue
                            }
                        }
                        if (key === 'gender') {
                            if (memberDetails[memberKey]?.toLowerCase() == member[key]?.toLowerCase()) {
                                continue
                            }
                        }
                        isDataChanged = true;
                        break;
                    }

                }
            }

            isDataChanged ? UpdateEditdetails() : editMember()
        }
    }


    const editMember = async () => {
        if (!m_obj_id) return
        const formData = new FormData();
        if (uploaded)
            formData.append('profile', profileImgFile, profileImgName);
        formData.append('senior_id', senior_id);
        formData.append('old_member_id', memberDetailsRef?.current?.id)
        // const {id} = relationList?.filter(item => item.name === relation)[0] || {}
        const memberAge = memberDetails.age ? memberDetails.age : memberDetails.dob ? getAge(memberDetails.dob) : null
        const memberDOB = memberDetails.dob ? dateFormat(memberDetails.dob) : null
        const memberProfile = memberDetails?.profile ? memberDetails?.profile : imageFile
        const payload = { ...memberDetails, old_member_id: memberInfo?.id, m_acc_id: m_obj_id, mobile: mobile?.split('.')?.join(''), relation: relation_id, profile: memberProfile, age: memberAge, dob: memberDOB };
        delete payload.permission
        for (let key in payload) {
            formData.append(key, payload[key])
        }


        function payloadClean(payloadList) {
            for (var key in payloadList) {
                if ((key === 'dob' || key === 'age') && (payloadList[key] === null || payloadList[key] === undefined)) {
                    delete payloadList[key];
                }
            }
            return payloadList
        }

        storeHandler(actions.SHOW_LOADER, true);

        try {
            isLoggedInUser ? await AuthServices.editProfileData(payloadClean(payload)) : await AuthServices.editMember(payloadClean(payload), id)
            storeHandler(actions.SHOW_LOADER, false);
            handleClose(null, null, 'Member Updated Successfully')
        } catch (err) {
            const { message } = err?.data || {};
            const data = { message, class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false);
            // handleClose()
        }
    }
    const UpdateEditdetails = async () => {
        storeHandler(actions.SHOW_LOADER, true);
        try {
            const { seniors } = await AuthServices.getmembersenior({
                old_member_id: memberInfo?.id,
                new_member_id: memberDetailsRef?.current?.id || null,
                senior_id: senior_id

            })

            setValidateSenior({ status: true, list: seniors });
            storeHandler(actions.SHOW_LOADER, false);
        } catch (error) {
            console.log(error)
            storeHandler(actions.SHOW_LOADER, false);
            let senior = error?.data?.seniors
            if (senior?.length === 0) {
                editMember()
            }
            console.log(error)
        }
    }
    const deleteProfileIMG = async () => {

        storeHandler(actions.SHOW_LOADER, true);
        try {
            const response = await AuthServices.deleteProfileIMG("member/" + memberInfo?.id);
            const { message } = response;
            storeHandler(actions.SHOW_LOADER, false);
            handleClose(null, null, 'Member Updated Successfully')
        } catch (error) {
            const { message } = error?.data || {};
            const data = { message, class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false);
        }
    }

    const deleteMember = async () => {
        if (!m_obj_id) return
        try {
            await AuthServices.deleteMember(memberInfo?.id, m_obj_id)
            storeHandler(actions.SHOW_LOADER, false);
            handleClose(null, null, 'Member Deleted Successfully')
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false);
            handleClose()
        }
    }

    const onConfirm = () => {
        deleteMember()
    }


    const getErrorMessage = (field) => {
        let message = errorMessages[field]
        if (field === 'dob') {
            return
        }
        return invalidForm && !memberDetails[field] && message
    }

    const getAge = (dob) => {
        var diff_ms = Date.now() - new Date(dob).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const addImg = (event) => {
        const img = event.target.files[0];
        if (img.type.startsWith("image") && img.type !== "image/gif") {
            const isValidFileSize = Number(((img.size / 1024) / 1024).toFixed(4)) <= 2;
            if (isValidFileSize) {
                setImageFile(img);
                setFileName(event.target.value);
                setImage(URL.createObjectURL(img));
                setInvalidFileSize(false);
            } else {
                setImage();
                setImageFile()
                setInvalidFileSize(true);
            }
        } else {
            setShowSnackBar(true);
            snackbarNotification({
                message: "Please upload a valid profile image",
                class: "error",
            });
        }
    };
    const textValidator = (event) => {
        const value = event.target.value;
        const invalid = value.length < 3 || value.length > 30;
        setMinLengthInvalid(invalid)
    }

    const emailValidator = async (event) => {
        const value = event.target.value;
        setInvalidEmail(!isEmailValid(value))


        if (value && isEmailValid(value)) {
            storeHandler(actions.SHOW_LOADER, true);
            try {
                const { member, seniors } = await AuthServices.getMemberEmailExist({ email: value })
                const { id, fname, lname, email, mobile, address, zipcode, sex: gender, age, relation, dob, profile, city, state, country, executive, permission: { view_video, live_video, chat, cen } } = member || {}
                const data = { id, fname, lname, email, mobile, address, zipcode, gender, age, relation, dob, profile, city, state, country, executive, view_video, live_video, chat, cen }
                let relationID = relationList?.filter(item => item?.name === relation)
                if (relationID?.length > 0) {
                    data['relation_id'] = relationID?.[0]?.['id']
                }
                // setMobile(mobile)
                setValidateEmail({ status: true, data })
                setSeniorList(seniors || [])
                memberDetailsRef.current = seniors
                storeHandler(actions.SHOW_LOADER, false);
            } catch (error) {
                console.log(error)
                storeHandler(actions.SHOW_LOADER, false);
            }
        }


    }

    const handleValidateEmail = () => {
        setMemberDetails(validateEmail['data'])
        // if (validateEmail?.['data']?.['profile']) setImage(validateEmail?.['data']?.['profile'])
        if (validateEmail?.['data']?.['profile'] !== null) {
            setImage(validateEmail?.['data']?.['profile']);
        } else {
            setImage(['data']?.['profile']);
        }

        setValidateEmail({ ...validateEmail, status: false });
    }
    const handledetail = () => {
        setMemberDetails(validateEmail['list'])
        if (validateSenior?.['list']?.['profile']) setImage(validateSenior?.['list']?.['profile'])
        setValidateSenior({ ...validateSenior, status: false })
        editMember()
    }
    const zipValidator = (value) => {
        setInvalidZip(!isZip(value))
    }

    const dateFormat = (val) => {
        const [month, date, year] = typeof val === 'string' && val.includes('-') ? new Date(`${val} 00:00`).toLocaleDateString().split('/') : new Date(val).toLocaleDateString().split('/');
        return `${year}/${month}/${date}`
    }

    const getAddress = async () => {
        const countryCode = countryList.filter(({ name }) => name === country)[0];
        if (countryCode && countryCode.code && zipcode) {
            storeHandler(actions.SHOW_LOADER, true);
            try {
                const { data } = await AuthServices.getAddress(zipcode, countryCode.code)
                const { place_name, state_name } = data || {};
                if (place_name && state_name) {
                    setMemberDetails({ ...memberDetails, city: place_name, state: state_name })
                }
                storeHandler(actions.SHOW_LOADER, false);
            } catch (err) {
                storeHandler(actions.SHOW_LOADER, false);
            }
        }
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogContent>
                    <img src={closeIcon} alt="close" className={`${styles.closeIco} cursor`} onClick={handleClose} />
                    <p className='txt-xl m-0 text-center mb-3'>Edit {isLoggedInUser ? 'Profile' : 'Member'}</p>
                    <div className='row m-0'>
                        <div className='d-flex flex-wrap col-md-9 col-xl-10 px-0 col-12'>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>First Name <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'fname' }} fullWidth placeholder="First Name" alphanumeric={true}
                                    value={fname} onChange={updateMemberDetails} helperText={getErrorMessage('fname') || minLengthInvalid && ('Enter 3 to 30 characters')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Last Name <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'lname' }} fullWidth placeholder="Last Name" alphanumeric={true}
                                    value={lname} onChange={updateMemberDetails} helperText={getErrorMessage('lname')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Email <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'email' }} fullWidth placeholder="Email"
                                    value={email} onChange={updateMemberDetails} onBlur={emailValidator} helperText={getErrorMessage('email') || invalidEmail && 'Enter valid email id'} />
                            </div>

                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Phone Number <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'mobile' }} type='text' fullWidth placeholder="Mobile number"
                                    value={mobile} onChange={handleChangeMobile} helperText={getErrorMessage('mobile') || invalidMobile && 'Enter valid phone number'} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Country <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    value={country}
                                    onChange={updateMemberDetails}
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'country' }}
                                    onBlur={getAddress}
                                    helperText={getErrorMessage('country')}
                                >
                                    {countryList && !!countryList?.length ?
                                        countryList.map(({ code, name }) => <MenuItem className='txt-md' value={name}>{name}</MenuItem>)
                                        : <MenuItem className='txt-md' value={''}>Loading...</MenuItem>}
                                </Select>
                                <FormHelperText>{getErrorMessage('country')}</FormHelperText>
                            </div>

                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Zip code <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'zipcode' }} type='text' fullWidth placeholder="Zip"
                                    value={zipcode} onChange={updateMemberDetails} onBlur={getAddress} helperText={getErrorMessage('zipcode') || invalidZip && 'Enter valid Zip'} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Address <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'address' }} fullWidth placeholder="Address"
                                    value={address} onChange={updateMemberDetails} helperText={getErrorMessage('address')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>City <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'city' }} fullWidth placeholder="City"
                                    value={city} onChange={updateMemberDetails} helperText={getErrorMessage('city')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>State <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'state' }} fullWidth placeholder="State"
                                    value={state} onChange={updateMemberDetails} helperText={getErrorMessage('state')} />
                            </div>


                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>DOB</label>
                                <DatePicker className='mt-2' inputProps={{ className: 'txt-md', name: 'dob' }} value={dob || null} onChange={handleDateChange} helperText={getErrorMessage('dob')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Gender <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    value={gender?.toLowerCase()}
                                    onChange={updateMemberDetails}
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'gender' }}
                                    helperText={getErrorMessage('gender')}
                                >

                                    <MenuItem className='txt-md' value={'male'}>Male</MenuItem>
                                    <MenuItem className='txt-md' value={'female'}>Female</MenuItem>
                                    <MenuItem className='txt-md' value={'others'}>Others</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage('gender')}</FormHelperText>

                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Relationship <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    value={relation_id}
                                    onChange={updateMemberDetails}
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'relation_id' }}
                                    helperText={getErrorMessage('relation_id')}
                                >
                                    {relationList && !!relationList?.length ?
                                        relationList.map((item) => <MenuItem className='txt-md' value={item.id}>{item.name}</MenuItem>)
                                        : <MenuItem className='txt-md' value={''}>Loading...</MenuItem>}
                                </Select>
                                <FormHelperText>{getErrorMessage('relation_id')}</FormHelperText>

                            </div>
                            <div className='px-3 mt-2 col-12'>
                                <span>Executive Member</span>
                                <Switch name="executive" color='primary' disabled={isLoggedInUser} checked={executive} onChange={updateMemberDetails} />
                            </div>
                            <div className='px-3 mt-2 mb-3  col-12'>
                                <span>Permissions</span>
                                <div className='row'>
                                    <div className='mt-2 col-3'>
                                        <span className='txt-primary'>View Motion Clips</span> <Switch name="view_video" color='primary' disabled={executive || isLoggedInUser} checked={view_video} onChange={updateMemberDetails} />
                                    </div>
                                    <div className='mt-2 col-3'>
                                        <span className='txt-primary'>Live Video</span> <Switch name="live_video" color='primary' disabled={executive || isLoggedInUser} checked={live_video} onChange={updateMemberDetails} />
                                    </div>
                                    <div className='mt-2 col-3'>
                                        <span className='txt-primary'>Chat</span> <Switch name="chat" color='primary' disabled={executive || isLoggedInUser} checked={chat} onChange={updateMemberDetails} />
                                    </div>
                                    <div className='mt-2 col-3'>
                                        <span className='txt-primary'>CEN</span> <Switch name="cen" color='primary' disabled={executive || isLoggedInUser} checked={cen} onChange={updateMemberDetails} />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='align-items-center col-12 col-xl-2 col-md-3 d-flex flex-column flex-wrap'>
                            <div className={`d-flex flex-column justify-content-between ${styles.addImg} mt-4`}>
                                <div className='text-center'> <img src={image ? image : avatar} alt="" className={styles.upload} /></div>
                                <label className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`} for='uploadImg'> Upload Image</label>
                                <TextField className={styles.uploadImg} inputProps={{ type: 'file', accept: "image/png, image/jpeg, image/jpg", id: 'uploadImg' }} onChange={addImg} />
                                {!["", null, undefined].includes(profile) && <label className={`txt-error ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`} onClick={deleteProfileIMG}> Delete Image</label>}
                            </div>
                        </div>
                    </div>

                    <div className='text-center mt-3'>
                        <Button variant="contained" color="primary" className='px-4' onClick={saveDetails}><img src={submitIcon} alt="submit icon" className='mr-2' />  <span className='txt-white txt-light'>Submit</span></Button>
                        {!isLoggedInUser && !(userId === id) && <Button variant="contained" color="primary" className='px-4 delete-btn ml-3' onClick={() => setOpenPopup(true)}> <img src={deleteIcon} className='mr-2 icon' /> <span className='txt-white txt-light'>Delete</span></Button>}
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
            <ConfirmDialog
                onClose={() => setValidateEmail({ status: false, data: '' })}
                open={validateEmail['status']}
                seniorList={seniorList}
                onConfirm={handleValidateEmail}
                message={'The provided email address is already associated with another senior account. Do you still want to continue with the same email address?'}
                isPrompt={false}
            />
            {validateSenior?.['status'] && <ConfirmDialog
                onClose={() => setValidateSenior({ status: false, data: '' })}
                seniorList={validateSenior?.['list'] || []}
                open={validateSenior?.status}
                message={"Editing the member details may impact other associated member accounts. Proceed with changes?"}
                isPrompt={false}
                onConfirm={handledetail}
            />}
            <ConfirmDialog isPrompt={false} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={`Are you Sure you want to Delete this Member?`} />
        </>
    )
}

export default EditMember