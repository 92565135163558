const LOG_IN = 'LOG_IN';
const MEMBER_DETAILS = 'MEMBER_DETAILS';
const ALL_MASTER_DETAILS = "ALL_MASTER_DETAILS";
const ALL_MASTER_LIST = "ALL_MASTER_LIST";
const SHOW_LOADER = 'SHOW_LOADER';
const SET_USER_DETAILS = 'SET_USER_DETAILS';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_RELATIONS = 'SET_RELATIONS';
const REFRESH_SENIOR = 'REFRESH_SENIOR';
const REFRESH_LOADER = 'REFRESH_LOADER';
const SELECTED_SENIOR = 'SELECTED_SENIOR';
const SELECTED_SENIOR_DATA = 'SELECTED_MASTER_DATA'

export {
    LOG_IN,
    SHOW_LOADER,
    MEMBER_DETAILS,
    SET_USER_DETAILS,
    SET_COUNTRY,
    SET_RELATIONS,
    REFRESH_SENIOR,
    REFRESH_LOADER,
    ALL_MASTER_DETAILS,
    ALL_MASTER_LIST,
    SELECTED_SENIOR,
    SELECTED_SENIOR_DATA
};
