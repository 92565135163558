import React, { useState, useEffect, useContext, useRef } from 'react';
import { TabPanel, Tab, Tabs, Snackbar } from 'components';
import TabContent from './tab-content/tab-content';

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import TabLabel from './components/dashboard-tab-label';
import { getSeniorIndex } from 'utils/validator';

const Dashboard = () => {

    const { dispatch } = useContext(GlobalContext);

    const [tabIndex, setTabValue] = useState(0);
    const [seniorList, setSeniorsList] = useState([]);
    const inputRef = useRef();
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    const getSeniorsList = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {

            const { multiple_master } = await AuthServices.getAllSen()
            const getSeniorAPIs = multiple_master?.map(({ master_obj_id }) => AuthServices.getAllSeniors(master_obj_id))
            const data = await Promise.all(getSeniorAPIs)
            const listOfSen = data?.reduce((initial, item) => {
                if (item && item.seniors) {
                    initial.push(...item.seniors);
                }
                return initial;
            }, [])
            setSeniorsList(listOfSen)
            storeHandler(actions.ALL_MASTER_LIST, listOfSen)
            storeHandler(actions.SHOW_LOADER, false)

        }
        catch (err) {
            const { data } = err || {}
            const { message } = data || "Member not found";
            setShowSnackBar(true);
            setSnackBarContent({ message, class: "error" })
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const setSelectedSeniorData = () => {
        if (seniorList?.length > 0) {
            const data = seniorList?.[getSeniorIndex()]
            storeHandler(
                actions.SELECTED_SENIOR_DATA,
                { m_acc_id: data?.['master_id'], m_obj_id: data?.['master_obj_id'] }
            )
        }
    }

    const handleTabChange = (event, newValue) => {
        localStorage.setItem("senior_index", newValue)
        setTabValue(newValue);
        setSelectedSeniorData()
    };

    useEffect(() => {
        getSeniorsList()
        setTabValue(getSeniorIndex())
    }, [])

    useEffect(() => {
        setSelectedSeniorData()
    }, [seniorList])

    const storeHandler = (type, payload) => dispatch({ type, payload })
    return (
        <div className='flex-grow-1 d-flex flex-column w-100'>
            {!!seniorList?.length &&
                <>
                    <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' indicatorColor='primary' ref={inputRef} className={'indicator-none'}>
                        {seniorList.map((info, i) => (<Tab key={i} className='fixed-label-width seniors align-top' label={<TabLabel seniorData={info} refreshSeniorList={getSeniorsList} tabIndex={tabIndex} index={i} />} id={`senior ${i}`} />))}
                    </Tabs>
                    {seniorList.map((seniorDetails, i) => (
                        <TabPanel key={i} value={tabIndex} index={i}>
                            <TabContent seniorDetails={seniorDetails} />
                        </TabPanel>))
                    }
                </>
            }

            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </div>
    );
}


export default Dashboard 