import locationIcon from 'assets/images/icon/location.svg';
import mail from 'assets/images/icon/mail.svg';
import phoneIcon from 'assets/images/icon/phone.svg';
import closeIcon from 'assets/images/icon/close.svg';


import styles from '../dashboard.module.scss';
import avatar from 'assets/images/icon/default.svg';

import { Dialog, DialogContent } from 'components';

const SeniorInfoPopup = ({ open, handleClose, seniorData }) => {

    const { profile, fname, lname, age, gender, email, address, zipcode, mobile, height, weight, height_unit, weight_unit, inchs,room_no } = seniorData || {}

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={open}
                onClose={handleClose}
                fullWidth={true}
                className={'senior-info'}
            >
                <DialogContent className={'senior-info-card p-0'}>
                    <div>
                        <div className={`${styles.userProfile} text-center p-4`}>
                            <img src={closeIcon} alt="close" className={`${styles.close} cursor`} onClick={handleClose} />
                            <img src={profile || avatar} alt='user' className={`${styles.userImg} my-3`} />
                            <p className='txt-white txt-xlg txt-bold m-0 capitalize'>{fname} {lname}</p>
                            <span className='txt-md capitalize'>{room_no && <span >Room No {room_no}, </span>}Age {age}, {gender}</span>
                        </div>
                        <div className={`${styles.userDetails} txt-white txt-md`}>
                            <div className=''>
                                <span><img src={mail} alt="mail" className={`${styles.icon} mr-2`} />{email ? email : '-'}</span>
                            </div>
                            <div className=''>
                                <span><img src={phoneIcon} alt="phone" className={`${styles.icon} mr-2`} />{mobile ? mobile : '-'}</span>
                            </div>
                            <div className=''>
                                <span><img src={locationIcon} alt="location" className='mr-1' /> {address ? address : "-"}, Zip code - {zipcode}</span>
                            </div>
                            
                        </div>
                        <div className={`${styles.userMeasurements} d-flex py-4 px-3`}>
                            <div className='mr-3'>
                                <div className='txt-md'>Height</div>
                                <div className='txt-white text-center'> {height || "-"} {height_unit || '-'} {height_unit === 'ft' && inchs ? inchs + " inchs" : "- inchs"}</div>
                            </div>
                            <div>
                                <div className='txt-md'>Weight</div>
                                <div className='txt-white text-center'>{weight || "-"} {weight_unit || '-'}</div>
                            </div>
                            
                        </div>
                    </div>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default SeniorInfoPopup;
