import httpClient from '../http-client';
import {
  LOGIN,
  GET_OTP,
  VERIFY_OTP,
  RESET_PASSWORD,
  GET_SENIORS,
  GET_SENSOR_TABLE,
  GET_DASHBOARD,
  GET_CHARTS,
  GET_MEMBERS,
  GET_PROFILE,
  EDIT_SENIOR,
  ADD_MEMBERS,
  EDIT_MEMBERS,
  EDIT_PROFILE,
  DELETE_MEMBER,
  GET_ADDRESS,
  GET_RELATIONS,
  GET_CHATS,
  SEND_CHATS,
  GET_VIDEO,
  VIDEO_FEED,
  GET_NOTIFICATIONS,
  DELETE_PROFILE_IMG,
  GET_SEN,
  MEMBER_EMAIL_EXIST,
  MEMBER_SENIOR
} from '../config';

const AuthServices = {

  login: (data) => httpClient.post(LOGIN, data).then((response) => response.data),
  getOtp: (data) => httpClient.post(GET_OTP, data).then((response) => response.data),
  verifyOtp: (data) => httpClient.post(VERIFY_OTP, data).then((response) => response.data),
  resetPassword: (data) => httpClient.post(RESET_PASSWORD, data).then((response) => response.data),

  getAllSeniors: (id) => httpClient.get(`${GET_SENIORS}?m_acc_id=${id}`).then((response) => response.data),
  getAllSen: () => httpClient.get(GET_SEN).then((response) => response.data),
  getDashboardData: (id) => httpClient.get(`${GET_DASHBOARD}/${id}`).then((response) => response.data),
  getCharts: (id, url) => httpClient.get(`${GET_CHARTS}/${url}&senior_id=${id}`).then((response) => response.data),
  getSensorTable: (id, url, startDate, endDate, action, macid) => httpClient.get(`${GET_SENSOR_TABLE}?sensor_name=${url}&senior_id=${id}&start_date=${startDate}&end_date=${endDate}&action=${action}&macid=${macid}`).then((response) => response.data),
  getNotifications: (senior_id, startDate, endDate) => httpClient.get(`${GET_NOTIFICATIONS}?senior_id=${senior_id}&start_date=${startDate}&end_date=${endDate}`).then((response) => response.data),
  editSenior: (id, data) => httpClient.post(`${EDIT_SENIOR}/${id}`, data).then((response) => response.data),

  getMembers: (m_id) => httpClient.get(`${GET_MEMBERS}?master_id=${m_id}`).then((response) => response.data),
  addMember: (data) => httpClient.post(`${ADD_MEMBERS}`, data).then((response) => response.data),
  editMember: (data, id) => httpClient.post(`${EDIT_MEMBERS}/${id}`, data).then((response) => response.data),
  deleteMember: (id, m_acc_id) => httpClient.delete(`${DELETE_MEMBER}?member_id=${id}&master_id=${m_acc_id}`).then((response) => response.data),
  getMemberEmailExist: (data) => httpClient.post(MEMBER_EMAIL_EXIST, data).then(response => response.data),
  getmembersenior:(data)=>httpClient.post(`${MEMBER_SENIOR}`, data).then(response=>response.data),

  getProfileData: (m_obj_id) => httpClient.get(`${GET_PROFILE}?master_id=${m_obj_id}`).then((response) => response.data),
  editProfileData: (data, id) => httpClient.post(`${EDIT_PROFILE}`, data).then((response) => response.data),

  getAddress: (zip, country) => httpClient.get(`${GET_ADDRESS}/${country}/${zip}`).then((response) => response.data),
  getRelations: () => httpClient.get(`${GET_RELATIONS}`).then((response) => response.data),

  getChats: (m_obj_id) => httpClient.get(`${GET_CHATS}?master_id=${m_obj_id}`).then((response) => response.data),
  sendChat: (data) => httpClient.post(`${SEND_CHATS}`, data).then((response) => response.data),

  getVideo: (id) => httpClient.get(`${GET_VIDEO}/${id}`).then((response) => response.data),
  getMotionClip: (url) => httpClient.get(url).then(response => response.data),
  getVideoFeed: (url) => httpClient.get(`${VIDEO_FEED}${url}`).then((response) => response.data),
  deleteProfileIMG: (data) => httpClient.post(DELETE_PROFILE_IMG + data).then((response) => response.data)

};

export default AuthServices;
