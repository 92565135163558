import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/styles/index.scss';
import App from 'app/app';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GlobalProvider } from 'context';


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <GlobalProvider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </GlobalProvider>
  // </React.StrictMode >
  ,)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
