import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDTTN7hj4Of0q7xC0Ii2czxIFcQpxk9ylI",
    authDomain: "ntelcare-3ba09.firebaseapp.com",
    projectId: "ntelcare-3ba09",
    storageBucket: "ntelcare-3ba09.appspot.com",
    messagingSenderId: "1022935892499",
    appId: "1:1022935892499:web:943ee567b53d42df0af5e6",
    measurementId: "G-51NZ7GBY28"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//export const serverKey = 'AAAA7iu7AhM:APA91bEAhod9Y_x3KP3u3zM7KtMoyQpWD3UM7wc9XX_GHwSpxxl37ko_tVdnLOsw2yyYyUbgUPjDWAS4YdBfWihmhQ_wkyaBWxGlLhvmdVga1mj4CSXjZNXXzU0i-KEkRZOxQaFoxy5k';
const vapidKey = 'BCXnZIosyXuJSNkJtPV87pToCHP6_k0cjCGLT2LsYs11vzZzs1-S99BioVarsuHeDHxBZ3oKgr4fpuqd-d9-dK0';

export const getCurrentToken = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js")
            return await getToken(messaging, {
                vapidKey,
                serviceWorkerRegistration
            })
        } catch (err) {
            return err
        }
    }
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
}