import { Step, StepConnector, StepLabel, Stepper, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Box } from 'components';
import moment from 'moment';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 'Calc(50% - 1px)',
        left: '-50%',
        right: '50%',
    },
    active: {
        '& $line': {
            backgroundColor:
                '#1C9282',
        },
    },
    completed: {
        '& $line': {
            backgroundColor:
                '#1C9282',
        },
    },
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#646464',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#646464',
        zIndex: 1,
        width: 15,
        height: 15,
        borderRadius: '50%',
    },
    active: {
        backgroundColor: '#0FEFD1',
        border: '.1rem solid #1E2730',
        outline: ".1rem solid #0FEFD1"
    },
    completed: {
        backgroundColor: '#1C9282',
    }

});


function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }} className={`${classes.root} ${active && classes.active} ${completed && classes.completed}`}>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));


function FallDetectionStepper({ data }) {

    const fallDetectionSteps = ['Detected', 'Confirmed', 'Calling']

    const nameMapper = {
        detected: "fall_detected",
        confirmed: "fall_confirmed",
        calling: 'calling'
    }

    let timeArr = []

    for (let index = 0; index < fallDetectionSteps.length; index++) {

        let stepName = fallDetectionSteps[index].toLocaleLowerCase()

        if (data[nameMapper[stepName]]) {
            var stillUtc = moment(data[nameMapper[stepName]]).toDate();
            var local = moment(stillUtc).format('hh:mm:ss A');
            timeArr.push(local)
        } else {
            break;
        }
    }


    const activeStep = timeArr.length === 0 ? '' : timeArr.length - 1

    const classes = useStyles();

    const stepperStatus = (ind) => {

        if (isNaN(activeStep) || activeStep === '') {
            return { completed: false, active: false }
        }

        return { completed: ind < activeStep ? true : false, active: ind == activeStep ? true : false }
    }

    return (
        <div className={`${classes.root}`}>
            <Stepper style={{ paddingLeft: "0", paddingRight: "0" }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {fallDetectionSteps.map((label, index) => {

                    const { active, completed } = stepperStatus(index) || {}

                    return (
                        <Step key={label}>
                            <div className='d-flex justify-content-center flex-column align-items-center' style={{ gap: "8px" }}>
                                <Typography style={{ color: "#AFAFAF", fontSize: '.7rem' }}>{label}</Typography>
                                <StepLabel StepIconComponent={() => ColorlibStepIcon(stepperStatus(index))}></StepLabel>
                                <Typography style={{ color: active ? "#0FEFD1" : "#AFAFAF", fontSize: '.8rem' }}>{timeArr[index] || '--'}</Typography>
                            </div>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

export default FallDetectionStepper;
