
import React, { useEffect, useState } from 'react';
import { TabPanel, Tab, Tabs } from 'components';
import HealthTab from '../health-tab/health-tab';
import LiveView from '../live-view/live-view';
import Members from '../members/members';

const TabContent = ({ seniorDetails }) => {

    const [tabIndex, setTabValue] = useState(0);
    const { id } = seniorDetails || {}

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        localStorage.setItem('tab', newValue)
    };

    useEffect(() => {
        setTabValue(Number(localStorage.getItem('tab')) || 0)
    }, [])

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Tabs value={tabIndex} onChange={handleTabChange} centered={true} indicatorColor='primary' className='btn-tabs mt-5 mb-4 indicator-none'>
                <Tab label='Health' id={`tab 1`} />
                <Tab label='Live View' id={`tab 2`} />
                <Tab label='Members' id={`tab 3`} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <HealthTab seniorId={id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <LiveView seniorId={id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <Members seniorId={id}  />
            </TabPanel>
        </div>
    );
}

export default TabContent