import moment from "moment";
import momentTimeZone from 'moment-timezone'

export const isURLValid = (input) => {
  const urlRegex =
    /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/;

  return urlRegex.test(input);
};

export const isEmailValid = (input) => {
  // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  // const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,15}/; // Regex for email Id don't accept +
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.\+]+\.[a-z]{2,15}/; // Regex for email Id accept +

  return emailRegex.test(input);
};

export const isPhonenoValid = (input) => {
  const phonenoRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}/;

  return phonenoRegex.test(input);
};

export const isPhonenNumValid = (input) => {
  // const phonenoRegex = /^[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
  // return phonenoRegex.test(input);
  return /^[0-9]{10}$/.test(input);
};

export const isNumber = (input) => {
  return /^\d+$/.test(input);
}

export const isName = (input) => {
  return /^[a-zA-Z]{0,30}$/.test(input);
}


export const isValidName = (value) => {
  return /^[a-zA-Z ]*$/.test(value);
}
export const isValidRoom = (input) => {
  if (input?.length > 5) return false
  return /^[a-zA-Z0-9 ]*$/.test(input);
}
export const isZip = (input) => {
  return /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(input);
}

export const mobileFormatter = (value) => {
  let newValue;
  if (value?.length >= 7)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6) + '.' + value?.slice(6, 10);
  else if (value?.length >= 4)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6);
  else
    newValue = value?.slice(0, 3);
  return newValue
}


export const timeZone = (inputTime, timezone, format = 'DD MMM YYYY hh:mm:ss A') => {
  // console.log(inputTime)
  if (!inputTime) return null

  try {
    //This will take the local system time
    return moment.utc(inputTime).local().format(format)
    //..................

    // const timeString = inputTime.replace(/\s[A-Z]+$/, '');
    // const formattedTime = moment(timeString).format(format)
    // return formattedTime
  } catch (error) {
    console.log(error)
    return null
  }

}

export const RPI_timezone = (timezone, date = new Date()) => {
  // console.log(timezone)
  const LocaltimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {

    //This will take the local system time
    const _convertedDateTime = moment.tz(date, LocaltimeZone);
    const _formattedDateTime = _convertedDateTime.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    return _formattedDateTime
    //.....................................

    // const convertedDateTime = moment.tz(date, LocaltimeZone).tz(timezone);
    // const formattedDateTime = convertedDateTime.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    // console.log(formattedDateTime)
    // return formattedDateTime

  } catch (error) {
    return error
  }
}


const RPI_to_UTC = (date, timezone, start) => {
  const td = momentTimeZone.tz(date, "GMT").format('YYYY-MM-DD');

  //This will take the local system time
  const LocaltimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (start) {
    return momentTimeZone.tz(`${td} 00:00:00`, "YYYY-MM-DD HH:mm:ss", LocaltimeZone).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  } else {
    return momentTimeZone.tz(`${td} 23:59:59`, "YYYY-MM-DD HH:mm:ss", LocaltimeZone).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  }
  //.....................................

  // if (start) {
  //   return momentTimeZone.tz(`${td} 00:00:00`, "YYYY-MM-DD HH:mm:ss", timezone).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  // } else {
  //   return momentTimeZone.tz(`${td} 23:59:59`, "YYYY-MM-DD HH:mm:ss", timezone).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  // }
}

export const daily_startEnd_time = (date, timezone, date_format = 'ddd, DD MMM YYYY HH:mm:ss [GMT]') => {
  const td = momentTimeZone.tz(date, "GMT").format('YYYY-MM-DD');
  // console.log(timezone)

  //This will take the local system time
  const LocaltimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = momentTimeZone.tz(`${td} 00:00:00`, "YYYY-MM-DD HH:mm:ss", LocaltimeZone).utc().format(date_format);
  const endDate = momentTimeZone.tz(`${td} 23:59:59`, "YYYY-MM-DD HH:mm:ss", LocaltimeZone).utc().format(date_format);
  return {
    startDate,
    endDate
  }
  //.......................................


  // const startDate = momentTimeZone.tz(`${td} 00:00:00`, "YYYY-MM-DD HH:mm:ss", timezone).utc().format(date_format);
  // const endDate = momentTimeZone.tz(`${td} 23:59:59`, "YYYY-MM-DD HH:mm:ss", timezone).utc().format(date_format);
  // return {
  //   startDate,
  //   endDate
  // }
}

export const StartEnd_time = (timezone, timeline) => {
  // console.log(timezone)
  const TL = (timeline) => {
    switch (timeline) {
      case 'day':
        return 'day'
      case 'weekly':
        return 'week'
      case 'monthly':
        return 'month'
      case "yearly":
        return 'year'
      default:
        return 'day'
    }
  }

  //This will take the local system time
  const LocaltimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = momentTimeZone.tz(LocaltimeZone)
  let a = time.clone().startOf(TL(timeline)).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
  let b = time.clone().endOf(TL(timeline)).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
  const startDate = RPI_to_UTC(a, LocaltimeZone, true)
  const endDate = RPI_to_UTC(b, LocaltimeZone, false)
  return {
    startDate,
    endDate
  }
  //.............................................

  // const time = momentTimeZone.tz(timezone)
  // let a = time.clone().startOf(TL(timeline)).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
  // let b = time.clone().endOf(TL(timeline)).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
  // const startDate = RPI_to_UTC(a, timezone, true)
  // const endDate = RPI_to_UTC(b, timezone, false)
  // return {
  //   startDate,
  //   endDate
  // }
}

export const getSeniorIndex = () => {

  const index = localStorage.getItem('senior_index')
  if (index && !isNaN(index)) {
    return +index
  } else {
    localStorage.setItem('senior_index', 0)
    return 0
  }

}