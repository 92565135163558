import { FormControl, InputLabel, TextField as MuiTextField, Select } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import showIcon from 'assets/images/icon/eye-icon.svg';
import hideIcon from 'assets/images/icon/eye-close.svg';
import { useState } from 'react';
import { Box, MenuItem } from 'components';

const TextField = (props) => {
    const {handleUnitChange, passwordfield, type, withoutAdornment, alphanumeric, max, chatAdornment, inputProps } = props;
    const [showPassword, setshowPassword] = useState(false);
    const showHidePassword = () => {
        setshowPassword(!showPassword);
    };


    const SelectInputMod = (props) => {
        const { label, required, where, helperText, ...rest } = props;
        return (
            <>
                <FormControl variant="filled" fullWidth size="small" required={required}>
                    <Select
                        style={{padding:'0 18px 0 0' , height:'30px' , position:'absolute', right:'-20px' , top: '-15px' }}
                        {...rest}
                        disableUnderline
                    />
                </FormControl>
                <Box sx={{marginTop: '-1rem'}}>
                    <label style={{ fontSize: '0.65rem', fontWeight: '500', color: 'red', padding: '2px 0 0 10px', }}>{helperText}</label>
                </Box>
            </>
        )
    }


    const onKeyPress = (e) => {
        if (type === 'number') {
            var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
            var charStr = String.fromCharCode(charCode);
            if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
        }

    };
    const onKeyDown = (event) => {
        if (event.target.type === 'number' && event.which === 69) {
            event.preventDefault();
        }
        if (alphanumeric) {
            const regex = /^[a-zA-Z ]+$/;
            // const regex = /^[a-zA-Z0-9 .-]+$/;

            if (regex.test(event.key)) {
                return
            }
            event.preventDefault()
        }
        if (max && Number(event.target.value) > Number(max)) {
            event.target.value = event.target.value.split('')[0]
            event.preventDefault()
        }

    };

    const validatePassword = (event) => {
        if (event.which === 32) {
            event.preventDefault();
        }
    };

    if (passwordfield && !withoutAdornment) {
        let passwordFieldProps = { ...props };
        delete passwordFieldProps.passwordfield;
        return (
            <MuiTextField
                {...passwordFieldProps}
                onKeyDown={validatePassword}
                inputProps={{
                    type: showPassword ? 'text' : 'password',
                    autoComplete: 'new-password',
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disableRipple={true} onClick={showHidePassword}>
                                <img src={!showPassword ? showIcon : hideIcon} alt="show" width={30} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    if (passwordfield && withoutAdornment) {

        let passwordFieldProps = { ...props };
        delete passwordFieldProps.passwordfield;
        delete passwordFieldProps.withoutAdornment
        return (
            <MuiTextField
                {...passwordFieldProps}
                onKeyDown={validatePassword}
                inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                }}
                InputProps={{
                    disableUnderline: true,
                }}
            />
        );
    }

    if (chatAdornment) {
        const { InputProps, onChange } = props;
        return <MuiTextField
            {...props}
            onChange={onChange}
            InputProps={InputProps}
        />
    }

    if (inputProps?.["name"] === "height" || inputProps?.["name"] === "weight") {

        const field = inputProps?.["name"] === "height" ? "height" : "weight"
        const unit = inputProps?.["unit"] || ''

        const weightValues = [
            {
                id: 0,
                value: "lbs"
            },
            {
                id: 1,
                value: "kg"
            }
        ]
        const heightValues = [
            {
                id: 0,
                value: "ft"
            },
            {
                id: 1,
                value: "cm"
            },
        ]

        return (
            <>
                <MuiTextField
                    {...props}
                    onKeyDown={onKeyDown}
                    onKeyPress={onKeyPress}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                {field === "weight" ?
                                    <SelectInputMod value={unit} onChange={(e)=> handleUnitChange(field , e.target.value)} >
                                        {
                                            weightValues?.map((option, index) => {
                                                return <MenuItem key={index} value={option?.value} >{option.value}</MenuItem>
                                            })
                                        }
                                    </SelectInputMod> :
                                    <SelectInputMod value={unit} onChange={(e)=> handleUnitChange(field , e.target.value)}>
                                        {
                                            heightValues?.map((option, index) => {
                                                return <MenuItem key={index} value={option?.value} >{option.value}</MenuItem>
                                            })
                                        }
                                    </SelectInputMod>
                                }
                            </InputAdornment>
                        ),
                    }}
                />
            </>
        )

    }

    return (
        <MuiTextField
            {...props}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            InputProps={{ disableUnderline: true }}
        />
    );
};

export default TextField;
