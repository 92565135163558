import styles from '../health-tab.module.scss';
import { getIcon } from 'app/dashboard/icons';

const Monitors = ({ name, value, customClass, isTabselected }) => {
    let renderedData;
    const BloodPressure = () => {
        return (
            <div className='text-left'>
                <span className={`txt-smd txt-white txt-bold d-block ${styles.pressure}`}>SYS:{value?.sys !== "00" ? (value?.sys ?? " - -") : " - -"}</span>
                <span className={`txt-smd txt-white txt-bold d-block ${styles.pressure}`}>DIA:{value?.dia !== "00" ? (value?.dia ?? " - -") : " - -"}</span>
            </div>
        )
    };
    const Sleep = () => {
        const strVal = value !== '--:--' ? value.split(":").reduce((first, second) => `${first} Hrs ${second} Min`) : '--:--';
        return (<span className='txt-md txt-white txt-bold'>{strVal}</span>)
    };
    const Sensor = () => {
        return (<span className='txt-md txt-white txt-bold'>{value}</span>)
    }

    if (name === 'Blood Pressure') {
        renderedData = <BloodPressure />
    } else if (name === 'Sleep') {

        renderedData = <Sleep />

    } else {
        renderedData = <Sensor />
    }

    return (
        <div className={`d-flex py-2 px-3 ${styles.monitorWrapper} ${customClass} align-items-center flex-grow-1 monitors w-100`}>
            {getIcon(name, isTabselected ? '#d8d8d8' : '#00B89F', `${styles.monitorIcon} mr-2`)}
            <div className='d-flex flex-column text-left'>
                <span className='txt-md txt-light'>{name}</span>
                {renderedData}
            </div>
        </div>
    )
}

export default Monitors