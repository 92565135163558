import { useState, useContext, useEffect } from 'react';
import { TextField, Dialog, DialogContent, Button, Select, MenuItem, DatePicker, Box } from 'components';
import avatar from 'assets/images/icon/default.svg';
import submitIcon from 'assets/images/icon/submit-icon.svg';
import styles from '../dashboard.module.scss';
import { GlobalContext, actions } from 'context';
import AuthServices from 'api/services/auth-services';
import { isEmailValid, isPhonenNumValid, mobileFormatter, isNumber, isName, isZip, isValidName, isValidRoom } from 'utils/validator';
import closeIcon from 'assets/images/icon/close.svg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { InputAdornment, Snackbar } from '@material-ui/core';

const errorMessages = {
    fname: 'Enter First Name',
    lname: 'Enter Last Name',
    address: 'Enter Address',
    gender: 'Select Gender',
    dob: 'Select Date of Birth',
    country: 'Select Country',
    zipcode: 'Enter Zip code',
    city: 'Enter City',
    state: 'Enter State',
    // email:"Enter Email"
    // mobile: 'Enter Phone Number',
    // height: 'Enter Height',
    // weight: 'Enter Height',
}

const EditSenior = ({ open, handleClose, seniorData }) => {
    const { state: { countryList }, dispatch } = useContext(GlobalContext);
    const dateOfBirth = seniorData?.dob?.includes('GMT') ? new Date(seniorData?.dob).toISOString().split('T')[0] : seniorData?.dob
    const [seniorDetails, setSeniorDetails] = useState({ ...seniorData, dob: dateOfBirth });
    const [invalidForm, setInvalidForm] = useState(false);

    const [imageFile, setImageFile] = useState(null);
    const [image, setImage] = useState(seniorData?.profile);
    const [invalidFileSize, setInvalidFileSize] = useState(false)
    const [invalidZip, setInvalidZip] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [minLengthInvalid, setMinLengthInvalid] = useState(false)
    const [profileImgName, setFileName] = useState({})
    const [mobile, setMobile] = useState('');
    const [invalidMobile, setInvalidMobile] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const { fname, lname, email, address, zipcode, gender, age, height, weight, blood_group, dob, profile, city, state, country, id, inchs, weight_unit, height_unit, room_no } = seniorDetails

    const snackbarNotification = (data) => {
        setSnackBarContent(data)
        setShowSnackBar(true)
    }
    const handleUnitChange = (key, value) => {
        if (key === 'weight') {
            setSeniorDetails({ ...seniorDetails, 'weight_unit': value })
        } else if (key === 'height') {
            setSeniorDetails({ ...seniorDetails, 'height_unit': value })
        }
    }


    const mobileValidator = (value) => {
        let checkValue = value?.split('.')?.join('');
        if (checkValue?.length > 0 && checkValue?.length < 10) setInvalidMobile(true);
        else setInvalidMobile(false);
    }

    const updateSeniorDetails = (event) => {
        const { name, value } = event.target;
        if (name === "mobile") {
            const mobileValue = value?.split('.')?.join('');
            if (isNumber(mobileValue)) {
                setMobile(mobileFormatter(mobileValue))
                mobileValidator(mobileValue)
            } else {
                setMobile(mobileValue.length > 0 ? mobile : '')
                mobileValidator(mobileValue.length > 0 ? mobile : '')
            }
        }
            
        if ((name === 'fname' || name === 'lname') && !isValidName(value)) return
        if (name === 'zipcode') zipValidator(value)
        if((name === 'room_no') && !isValidRoom(value)) return
        if (name === 'email' && value.length > 50) return
        setSeniorDetails({ ...seniorDetails, [name]: value })
    }

    const handleDateChange = (date) => {
        setSeniorDetails({ ...seniorDetails, dob: date })
    }

    const saveDetails = () => {
        let isInvalidForm = false;
        let requiredFields = Object.keys(errorMessages);
        requiredFields.forEach(item => {
            if (!seniorDetails[item]) {
                isInvalidForm = true
                setInvalidForm(true);
                return
            }
        })
        if (!isInvalidForm && !invalidFileSize && !invalidEmail && (!invalidMobile) && !invalidZip) {
            editSenior()
        }
    }

    const editSenior = async () => {
        const formData = new FormData();
        const payload = { ...seniorDetails, profile: imageFile || 'null', age: getAge(seniorDetails.dob), dob: dateFormat(seniorDetails.dob), mobile: mobile?.split('.')?.join('') };

        for (let key in payload) {
            formData.append(key, payload[key])
        }
        storeHandler(actions.SHOW_LOADER, true);

        try {
            await AuthServices.editSenior(id, payload)
            storeHandler(actions.SHOW_LOADER, false);
            handleClose(null, null, true)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false);
            handleClose()
        }
    }

    const deleteProfileIMG = async () => {

        storeHandler(actions.SHOW_LOADER, true);
        try {
            const response = await AuthServices.deleteProfileIMG("senior/" + id);
            const { message } = response;
            storeHandler(actions.SHOW_LOADER, false);
            handleClose(null, null, 'Senior  Updated Successfully')
        } catch (error) {
            const { message } = error?.data || {};
            const data = { message, class: 'error' }
            storeHandler(actions.SHOW_LOADER, false);
        }
    }

    const getErrorMessage = (field) => {
        let message = errorMessages[field]
        return invalidForm && !seniorDetails[field] && message
    }

    const getAge = (dob) => {
        var diff_ms = Date.now() - new Date(dob).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }


    const addImg = (event) => {
        const img = event.target.files[0];
        if (img.type.startsWith("image") && img.type !== "image/gif") {
            const isValidFileSize = Number(((img.size / 1024) / 1024).toFixed(4)) <= 2;
            if (isValidFileSize) {
                setImageFile(img);
                setFileName(event.target.value);
                setImage(URL.createObjectURL(img));
                setInvalidFileSize(false);
            } else {
                setImage();
                setImageFile()
                setInvalidFileSize(true);
            }
        } else {
            setShowSnackBar(true);
            snackbarNotification({
                message: "Please upload a valid profile image",
                class: "error",
            });
        }
    };
    const dateFormat = (val) => {
        const [month, date, year] = new Date(val).toLocaleDateString().split('/');
        return `${year}/${month}/${date}`
    }

    const textValidator = (event) => {
        const value = event.target.value;
        const invalid = value.length < 3 || value.length > 30;
        setMinLengthInvalid(invalid)
    }

    const emailValidator = (event) => {
        const value = event.target.value;
        setInvalidEmail(!!value ? !isEmailValid(value) : false)
    }

    const zipValidator = (value) => {
        setInvalidZip(!isZip(value))
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const getAddress = async () => {
        const countryCode = countryList.filter(({ name }) => name === country)[0];
        if (countryCode && countryCode.code && zipcode) {
            storeHandler(actions.SHOW_LOADER, true);
            try {
                const { data } = await AuthServices.getAddress(zipcode, countryCode.code)
                const { place_name, state_name } = data || {};
                if (place_name && state_name) {
                    setSeniorDetails({ ...seniorDetails, city: place_name, state: state_name })
                }
                storeHandler(actions.SHOW_LOADER, false);
            } catch (err) {
                storeHandler(actions.SHOW_LOADER, false);
            }
        }
    }

    useEffect(() => {
        setMobile(mobileFormatter(seniorData?.mobile));
    }, []);

    return (
        <>
            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogContent>
                    <img src={closeIcon} alt="close" className={`${styles.closeIco} cursor`} onClick={handleClose} />
                    <p className='txt-xl m-0 text-center mb-3'>Edit Senior</p>
                    <div className='row m-0'>
                        <div className='d-flex flex-wrap col-md-9 col-xl-10 px-0 col-12'>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>First Name <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'fname' }} fullWidth placeholder="Name" alphanumeric={true}
                                    value={fname} onChange={updateSeniorDetails} helperText={getErrorMessage('fname') || minLengthInvalid && ('Enter 3 to 30 characters')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Last Name <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'lname' }} fullWidth placeholder="Name" alphanumeric={true}
                                    value={lname} onChange={updateSeniorDetails} helperText={getErrorMessage('lname')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Email</label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'email' }} fullWidth placeholder="Email"
                                    value={email} onChange={updateSeniorDetails} onBlur={emailValidator} helperText={getErrorMessage('email') || invalidEmail && 'Enter valid email id'} />
                            </div>

                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Phone Number</label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'mobile' }} type='text' fullWidth placeholder="Mobile number"
                                    value={mobile} onChange={updateSeniorDetails} helperText={getErrorMessage('mobile') || invalidMobile && 'Enter valid phone number'} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Address <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'address' }} fullWidth placeholder="Address"
                                    value={address} onChange={updateSeniorDetails} helperText={getErrorMessage('address')} />
                            </div>

                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Country <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    value={country}
                                    onChange={updateSeniorDetails}
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'country' }}
                                    onBlur={getAddress}
                                    helperText={getErrorMessage('country')}
                                >
                                    {countryList && !!countryList?.length ?
                                        countryList.map(({ code, name }) => <MenuItem className='txt-md' value={name}>{name}</MenuItem>)
                                        : <MenuItem className='txt-md' value={''}>Loading...</MenuItem>}
                                </Select>
                                <FormHelperText>{getErrorMessage('country')}</FormHelperText>

                            </div>

                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Zip code <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'zipcode' }} type='text' fullWidth placeholder="Zip"
                                    value={zipcode} onChange={updateSeniorDetails} onBlur={getAddress} helperText={getErrorMessage('zipcode') || invalidZip && 'Enter valid Zip'} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>City <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'city' }} fullWidth placeholder="City"
                                    value={city} onChange={updateSeniorDetails} helperText={getErrorMessage('city')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>State <span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'state' }} fullWidth placeholder="State"
                                    value={state} onChange={updateSeniorDetails} helperText={getErrorMessage('state')} />
                            </div>


                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>DOB <span className={styles.asteriskError}>*</span></label>
                                <DatePicker className='mt-2' senior={true} inputProps={{ className: 'txt-md', name: 'dob' }} value={dob || null} onChange={handleDateChange} helperText={getErrorMessage('dob')} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Room Number </label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'room_no' }} type='text' fullWidth placeholder="Room Number"
                                    value={room_no} onChange={updateSeniorDetails}  helperText={getErrorMessage('room_no')} />
                            </div>
                            <div className='col-lg-2 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Gender <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    value={gender?.toLowerCase()}
                                    onChange={updateSeniorDetails}
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'gender' }}
                                    helperText={getErrorMessage('gender')}
                                >
                                    <MenuItem className='txt-md' value={'male'}>Male</MenuItem>
                                    <MenuItem className='txt-md' value={'female'}>Female</MenuItem>
                                    <MenuItem className='txt-md' value={'others'}>Others</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage('gender')}</FormHelperText>
                            </div>
                            <div className='col-lg-2 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Height</label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'height', unit: height_unit }} type='number' fullWidth placeholder="Height"
                                    value={height} onChange={updateSeniorDetails} handleUnitChange={handleUnitChange} />
                            </div>
                            {height_unit === "ft" && <div className='col-lg-2 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Inch</label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'inchs' }} type='number' fullWidth placeholder="Inch"
                                    value={inchs} onChange={updateSeniorDetails} />
                            </div>}
                            <div className='col-lg-2 col-md-6 col-12 mb-3'>
                                <label className='txt-md'>Weight</label>
                                <TextField variant="filled" className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'weight', unit: weight_unit }} type='number' fullWidth placeholder="Weight"
                                    value={weight} onChange={updateSeniorDetails} handleUnitChange={handleUnitChange} />
                            </div>

                        </div>
                        <div className='align-items-center col-12 col-md-3 col-xl-2 d-flex flex-column flex-wrap'>
                            <div className={`d-flex flex-column justify-content-between ${styles.addImg} mt-4`}>
                                <div className='text-center'> <img src={image ? image : avatar} alt="" className={styles.upload} /></div>
                                <label className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`} for='uploadImg'> Upload Image</label>
                                {!["", null, undefined].includes(profile) && <label className={`txt-error ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`} onClick={deleteProfileIMG}> Delete Image</label>}
                                <TextField className={styles.uploadImg} inputProps={{ type: 'file', accept: "image/png, image/jpeg, image/jpg", id: 'uploadImg' }} onChange={addImg} />
                                {invalidFileSize && <span className='mt-2 txt-error'>Image size should be less than 2MB</span>}
                            </div>
                        </div>
                    </div>

                    <div className='text-center mt-3'>
                        <Button variant="contained" color="primary" className='px-4' onClick={saveDetails}><img src={submitIcon} className='mr-2' /> <span className='txt-white txt-light'>Submit</span></Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={2000} closeSnackBar={setShowSnackBar} />
        </>
    )
}

export default EditSenior