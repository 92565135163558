import React, { useRef } from "react";
import styles from '../live-view.module.scss';
import cancel from 'assets/images/icon/close.svg';
import { VideoPreviewer } from './video-previewer';

const VideoPlayer = ({ closePreview, video, name, thumbnail }) => {
    const playerRef = useRef(null);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: video,
            type: 'video/mp4'
        }],
        poster: thumbnail
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            player.log('player is waiting');
        });

        player.on('dispose', () => {
            player.log('player will dispose');
        });
    };
    return (<div className={`d-flex align-items-center justify-content-center ${styles.videoPreviewer}`}>
        <div className={styles.videoWrapper}>
            <div className={`${styles.close} txt-md`} onClick={() => closePreview(false)}> <img src={cancel} alt="close-preview" /></div>
            <div className={`${styles.title} px-3 py-2 txt-md w-100`}>{name}</div>
            <VideoPreviewer options={videoJsOptions} onReady={handlePlayerReady} />
        </div>
    </div>)
}

export default VideoPlayer