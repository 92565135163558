import React, { useState, useEffect, useContext } from 'react';
import styles from './health-tab.module.scss';
import battery from 'assets/images/icon/battery.svg';
import watchIcon from 'assets/images/icon/watch.svg';
import { TabPanel, Tab, Tabs, DatePicker, Snackbar, Menu, MenuItem } from 'components';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import Monitors from './components/monitors';
import Sensors from './components/sensors';
import TabContent from './components/health-tab-content';
import { getIcon } from '../icons';
import clock from 'assets/images/icon/clock.svg';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import wifiGood from "assets/images/icon/wifi-good-strength.svg"
import wifiWeek from "assets/images/icon/wifi-weak.svg"
import wifiM1 from "assets/images/icon/wifi-moderate1.svg"
import wifiM2 from "assets/images/icon/wifi-moderate2.svg"
import wifiOff from "assets/images/icon/wifi-off.svg"
import LAN_Connected from "assets/images/icon/LAN_Connected.svg";
import LAN_Not_Connected from "assets/images/icon/LAN_Not Connected.svg";
import infoIcon from 'assets/images/icon/info-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icon/filter_icon.svg'
import { RPI_timezone, StartEnd_time, daily_startEnd_time, timeZone } from 'utils/validator';
import newwifiGood from "assets/images/wifi-newpatch-goodstrength.svg"
import newwifiWeek from "assets/images/icon/wifi-newpatch-moderate-weak.svg"
import newwifiM1 from "assets/images/icon/wifi-newpatch-moderate1.svg"
import newwifiM2 from "assets/images/icon/wifi-newpatch-moderate-weak.svg"

const TL = (timeline) => {
    switch (timeline) {
        case 'day':
            return 'day'
        case 'weekly':
            return 'week'
        case 'monthly':
            return 'month'
        case "yearly":
            return 'year'
        default:
            return 'day'
    }
}

const NotificationItems = ({ notificationItem, noborder }) => {
    const { type, message, sensor_name, alert, date_time, tz_abbreviation = "", timezone, date, time } = notificationItem || {};
    const stylesMapper = {
        "WARN": 'txt-yellow',
        "DANGER": 'txt-error-dark'
    }

    const iconMapper = {
        "SOS": getIcon('Urgent Band', '#00B89F', styles.notificationIcon),
        "BathRoom": getIcon('bathroom', "#00B89F", styles.noNotifcationIcon),
        "PillBox": getIcon('Pill Box', '#00B89F', styles.notificationIcon),
        "Door": getIcon('door', '#00B89F', styles.notificationIcon),
        "Shower": getIcon('shower', '#00B89F', styles.notificationIcon),
        "SleepBelt": getIcon('Sleep Belt', '#00B89F', styles.notificationIcon),
        "FallDetection": getIcon('FallDetection', '#00B89F', styles.noNotifcationIcon),
        "watch": <img src={watchIcon} alt="watch" />
    }

    return (
        <div className={`${styles.timeLineItem} d-flex align-items-center py-3 ${!noborder ? 'border-bottom' : ''}`}>
            <div className={`${styles.timeLineItemImg} d-flex align-items-center justify-content-center mr-3`}>
                {iconMapper[sensor_name[0]]}
            </div>
            <div className='d-flex flex-column'>
                <span className={`${sensor_name?.includes('SOS') || alert.danger ? stylesMapper["DANGER"] : stylesMapper["WARN"]} mb-1 txt-md`}>{message}</span>

                {/* This is for local time conversion */}
                {<span className='txt-md txt-grey-ltr'>{timeZone(date + " " + time, timezone) ? timeZone(date + " " + time, timezone, 'MMM DD, YYYY hh:mm A') + " " + (tz_abbreviation || '') : "-:-"}</span>}
                {/* {<span className='txt-md txt-grey-ltr'>{timeZone(date_time, timezone) ? timeZone(date_time, timezone, 'MMM DD, YYYY hh:mm A') + " " + (tz_abbreviation || '') : "-:-"}</span>} */}
            </div>
        </div>
    )
}

const HealthTab = ({ seniorId }) => {

    const { state: { refresh_senior, userData }, dispatch } = useContext(GlobalContext);
    const { permission } = userData || {}
    const [loading, setLoading] = useState(false)
    const [tabIndex, setTabValue] = useState(0);
    const [watchStatus, setWatchStatus] = useState({});
    const [heartRateData, setHearRateData] = useState([]);
    const [bloodPressureData, setbloodPressureData] = useState([]);
    const [stepsData, setStepsData] = useState([]);
    const [caloriesData, setCaloriesData] = useState([]);
    const [bloodOxygenData, setbloodOxygenData] = useState([]);
    const [sleepData, setSleepData] = useState([]);
    const [sensorsList, setSensorsList] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [pillBoxData, setpillBoxData] = useState([]);
    const [doorData, setdoorData] = useState([]);
    const [bathroomData, setbathroomData] = useState([]);
    const [showerData, setShowerData] = useState([]);
    const [sosData, setSosData] = useState([]);
    const [sleepBeltData, setSleepBeltData] = useState([]);
    const [sleepBeltChairData, setSleepBeltChairData] = useState([]);
    const [connectionType, setConnection] = useState({});
    const { wifi_strength, network_type, vpn_latest,external_wifi } = connectionType;
    const [fallDetectionData, setFallDetetctionData] = useState([])
    const [notifcation, setNotification] = useState([]);
    const [copyNotifcation, setCopyNotifcation] = useState([])
    const [watchDetails, setWatchDetails] = useState({ name: '', macid: '' });
    const [isWatchAvailable, setIsWatchAvailable] = useState(false);
    const [CENFilterOpne, setCENFilterOpen] = useState(false)
    const [CENFilterValue, setCENFilterValue] = useState('all')
    const [anchorEl, setAnchorEl] = useState(false)
    const [tz, setTz] = useState({})

    const handleCENFilterOpne = (event) => {
        const El = event.currentTarget
        setCENFilterOpen(true)
        setAnchorEl(El)
    }

    const handleFilterCEN = (value) => {

        if (!value || value === 'all') {
            setCopyNotifcation([...notifcation])
        } else {
            const newNotificationList = notifcation?.filter(item => {
                if (item['sensor_name']?.[0] === value) {
                    return item
                }
            })
            setCopyNotifcation(newNotificationList)
        }

        setCENFilterOpen(false)
        setCENFilterValue(value)
    }
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const urls = {
        heartRate: 'heart_rate',
        bloodPressure: 'blood_pressure',
        setps: 'steps',
        sleep: 'sleep',
        calories: 'calories',
        bloodOxygen: 'blood_oxygen',
        shower: 'Shower',
        pillBox: 'PillBox',
        door: 'Door',
        SOS: 'SOS',
        sleepBelt: 'SleepBelt',
        sleepBeltChair: 'SleepBelt-Chair',
        fallDetection: 'FallDetection',
        bathRoom: 'BathRoom'
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        localStorage.setItem('healthtab', newValue)
        localStorage.removeItem('selectedsensor')
    };

    const handleSensorChange = (newValue, item) => {
        setTabValue(newValue + 6);
        localStorage.setItem('selectedsensor', newValue + 6)
        localStorage.removeItem('healthtab')

        const updatedMacID = {}
        if (sensorsList[item]['deviceList']) {
            if (sensorsList[item]['deviceList']?.length) {
                updatedMacID[item] = sensorsList[item]['deviceList'][0]['mac_id']
            }
        }
        localStorage.setItem('selectedsensorDetail', JSON.stringify(updatedMacID))
    };

    const refreshHealthTab = async () => {

        storeHandler(actions.SHOW_LOADER, true);

        const data = await getDashboardData()
        const { watch_status, sensors_status, sensors_active, wifi_strength, vpn_latest, network_type, fall_data, timezone, tz_offset, tz_abbreviation ,external_wifi} = data || {};

        const { notifications } = watch_status || {};
        setNotification([...notifications])
        setCopyNotifcation([...notifcation])
        let _tz = { timezone, tz_offset, tz_abbreviation: '' }
        setTz(_tz)

        sensors_active?.map((el) => {
            if (el?.sensor_name === "watch") {
                setWatchDetails({ name: el?.name, macid: el?.mac_id });
            }
        })

        let active_sensor_data_list = {}
        sensors_active?.forEach(item => {

            if (item['sensor_name'] === 'watch') return

            if (active_sensor_data_list[item['sensor_name']] === undefined) {
                active_sensor_data_list[item['sensor_name']] = { 'deviceList': [item] }
            } else {
                active_sensor_data_list[item['sensor_name']]['deviceList'].push(item)
            }

        })

        Object.keys(sensors_status)?.forEach(item => {

            Object.keys(active_sensor_data_list)?.forEach(itm => {

                if (itm === sensors_status[item]['name']) {
                    active_sensor_data_list[itm]['data'] = { ...sensors_status[item], id: item }
                } else if (itm === 'FallDetection') {
                    active_sensor_data_list[itm]['data'] = { id: 'FallDetection', bool: true, name: 'FallDetection', ...fall_data[0] }
                }

            })

        })

        setSensorsList(active_sensor_data_list)

        const get_mac_id = (sensor_name) => {
            if (active_sensor_data_list[sensor_name]) {
                if (active_sensor_data_list[sensor_name]['deviceList']?.length > 0) {
                    return active_sensor_data_list[sensor_name]['deviceList'][0]['mac_id']
                }
            }

            return ''
        }

        setConnection({
            wifi_strength,
            network_type,
            vpn_latest,
            external_wifi
        })

        const newDate = RPI_timezone(timezone)
        getHeartRateData('day', _tz)
        getBPData('day', _tz)
        getStepData('day', _tz)
        getSleepData('day', _tz)
        getCaloriesData('day', _tz)
        getOxygenData('day', _tz)
        getPillBoxData('day', newDate, get_mac_id('PillBox'), _tz);
        getDoorData('day', newDate, get_mac_id('Door'), _tz)
        getShowerData('day', newDate, get_mac_id('Shower'), _tz)
        getSosData('day', newDate, get_mac_id('SOS'), _tz)
        getSleepBeltData('day', newDate, get_mac_id('SleepBelt'), _tz)
        getSleepBeltChairData('day', newDate, get_mac_id('SleepBelt-Chair'), _tz)
        getFallDetectionData('day', newDate, get_mac_id('FallDetection'), _tz)
        getBathRoomData('day', newDate, get_mac_id('BathRoom'), _tz)
        setInitialDate(newDate, _tz)

        storeHandler(actions.REFRESH_LOADER, false);
    }

    useEffect(() => {
        if (seniorId) {

            if (isNaN(Number(localStorage.getItem('selectedsensor'))) || !(localStorage.getItem('selectedsensor'))) {
                setTabValue(Number(localStorage.getItem('healthtab')) || 0)
                localStorage.removeItem('selectedsensor')
            } else {
                setTabValue(Number(localStorage.getItem('selectedsensor')))
                localStorage.removeItem('healthtab')
            }
            refreshHealthTab()

        }
    }, [seniorId])

    const getHeartRateData = (timeLine, _tz) => getChartData(urls.heartRate, setHearRateData, timeLine, _tz);
    const getBPData = (timeLine, _tz) => getChartData(urls.bloodPressure, setbloodPressureData, timeLine, _tz);
    const getStepData = (timeLine, _tz) => getChartData(urls.setps, setStepsData, timeLine, _tz);
    const getSleepData = (timeLine, _tz) => getChartData(urls.sleep, setSleepData, timeLine, _tz);
    const getCaloriesData = (timeLine, _tz) => getChartData(urls.calories, setCaloriesData, timeLine, _tz);
    const getOxygenData = (timeLine, _tz) => getChartData(urls.bloodOxygen, setbloodOxygenData, timeLine, _tz);
    // const getBathroomData = (timeLine) => getChartData(urls.bathRoom, setbathroomData, timeLine);

    const getShowerData = (timeLine, date, macid, _tz) => getTableData(urls.shower, setShowerData, timeLine, date, macid, _tz)
    const getPillBoxData = (timeLine, date, macid, _tz) => getTableData(urls.pillBox, setpillBoxData, timeLine, date, macid, _tz);
    const getDoorData = (timeLine, date, macid, _tz) => getTableData(urls.door, setdoorData, timeLine, date, macid, _tz)
    const getSosData = (timeLine, date, macid, _tz) => getTableData(urls.SOS, setSosData, timeLine, date, macid, _tz);
    const getSleepBeltData = (timeLine, date, macid, _tz) => getTableData(urls.sleepBelt, setSleepBeltData, timeLine, date, macid, _tz);
    const getSleepBeltChairData = (timeLine, date, macid, _tz) => getTableData(urls.sleepBeltChair, setSleepBeltChairData, timeLine, date, macid, _tz);
    const getFallDetectionData = (timeLine, date, macid, _tz) => getTableData(urls.fallDetection, setFallDetetctionData, timeLine, date, macid, _tz)
    const getBathRoomData = (timeLine, date, macid, _tz) => getTableData(urls.bathRoom, setbathroomData, timeLine, date, macid, _tz)
    const getDashboardData = async () => {
        const { dashboard } = await AuthServices.getDashboardData(seniorId);
        const { watch_status, sensors_active } = dashboard || {};
        const keys = sensors_active?.map((ele) => ele.sensor_name);

        let showWatchData = false;
        if (keys.includes("watch")) {
            showWatchData = true;
            setIsWatchAvailable(true);
        }
        if (showWatchData) setWatchStatus({ ...watch_status })


        return dashboard
    }

    const getTableData = async (graphUrl, func, timeline, date, macid, _tz) => {
        let timeZone = _tz || tz, sTime = '', eTime = ''

        if (timeline === 'day') {
            const { startDate, endDate } = daily_startEnd_time(date, timeZone?.timezone)
            sTime = startDate
            eTime = endDate
        } else {
            const { startDate, endDate } = StartEnd_time(timeZone?.timezone, timeline)
            sTime = startDate
            eTime = endDate
        }
        setLoading(true)

        try {
            const data = await AuthServices.getSensorTable(seniorId, graphUrl, sTime, eTime, TL(timeline), macid);
            if (typeof func === 'function') func(data)
            setLoading(false)
        } catch (err) {
            const { data } = err || {}
            const { message } = data || "member not found";
            setShowSnackBar(true);
            setSnackBarContent({ message, class: "error" })
            storeHandler(actions.SHOW_LOADER, false);
        }
    }

    const getChartData = async (graphUrl, func, timeLine, _tz) => {

        let timeZone = _tz?.hasOwnProperty('timezone') ? _tz : tz
        //because without timwzone it will call API with worng start and end time
        if (Object.keys(timeZone || {})?.length <= 0) return
        const { startDate, endDate } = StartEnd_time(timeZone?.timezone, timeLine)

        const url = timeLine ? `${graphUrl}/${timeLine}?start_date=${startDate}&end_date=${endDate}` : `${graphUrl}`;
        const data = await AuthServices.getCharts(seniorId, url);

        const graphData = data[graphUrl];

        if (graphData && timeLine === 'day') {
            const timeLine = Object.keys(graphData)
            const graphItems = graphUrl !== 'blood_pressure' ? graphData.map(({ time, value, avg }) => ({ time, value: Number(graphUrl === 'sleep' ? value : avg) })) :
                graphData.map(({ time, avg_dia, avg_sys }) => ({ time, Systolic: avg_sys || 0, Diastolic: avg_dia || 0 }));
            const updatedtime = graphItems.map((item, i) => ({ ...item, time: i })
            )
            func(updatedtime)
        } else if (graphData) {
            let graphItems;
            if (graphUrl === 'blood_pressure') {
                graphItems = graphData.map((item) => ({ ...item, Systolic: item.max_sys, Diastolic: item.max_dia }));
            } else if (graphUrl === 'Shower' || graphUrl === 'PillBox' || graphUrl === 'Door' || graphUrl === 'SOS' || graphUrl === 'FallDetection') {
                graphItems = graphData.map((item) => ({ ...item, value: Number(item.value) }));
            } else {
                graphItems = graphData
            }
            func(graphItems)
        }
        else {
            func([])
        }
        storeHandler(actions.SHOW_LOADER, false)

    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const sensorMapper = {
        'Shower': { value: showerData, func: getShowerData, name: 'Shower' },
        'Door': { value: doorData, func: getDoorData, name: 'Door' },
        'PillBox': { value: pillBoxData, func: getPillBoxData, name: 'PillBox' },
        'SOS': { value: sosData, func: getSosData, name: 'Urgent Band' },
        'SleepBelt': { value: sleepBeltData, func: getSleepBeltData, name: 'Sleep Belt (Bed)' },
        'SleepBelt-Chair': { value: sleepBeltChairData, func: getSleepBeltChairData, name: 'Sleep Belt (Chair / Couch)' },
        'FallDetection': { value: fallDetectionData, func: getFallDetectionData, name: 'FallDetection' },
        'BathRoom': { value: bathroomData, func: getBathRoomData, name: 'BathRoom' }
    }

    const getNotifications = async (startDate, endDate) => {

        try {
            const { data } = await AuthServices.getNotifications(seniorId, startDate, endDate)

            let notificationData = data?.length > 0 ? data.sort((a, b) => moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss') - moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss')).reverse() : data
            setNotification(notificationData)
            setCopyNotifcation(notificationData)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (error) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const handleDateChange = (date, timeZone) => {
        const newDate = RPI_timezone(timeZone?.timezone, date)
        const { startDate, endDate } = daily_startEnd_time(newDate, timeZone?.timezone, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        setSelectedDate(newDate)
        getNotifications(startDate, endDate)
        setCENFilterValue('all')
    }

    const setInitialDate = (newDate, timeZone) => {

        const { startDate, endDate } = daily_startEnd_time(newDate, timeZone?.timezone, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        setSelectedDate(newDate)
        getNotifications(startDate, endDate)
    }

    const handleCheckWifiStrength = (wifi_strength, external_wifi) => {
        if (external_wifi === true) {
            if (wifi_strength === "Not Good") return wifiWeek;
            if (wifi_strength === "Okay") return wifiM2;
            if (wifi_strength === "Very Good") return wifiM1;
            if (wifi_strength === "Amazing") return wifiGood;
            return wifiWeek
        } else if (external_wifi === false || external_wifi === null) {
            if (wifi_strength === "Not Good") return newwifiWeek;
            if (wifi_strength === "Okay") return newwifiM2;
            if (wifi_strength === "Very Good") return newwifiM1;
            if (wifi_strength === "Amazing") return newwifiGood;
            return wifiWeek
        }
    }

    useEffect(() => {
        if (refresh_senior) {
            refreshHealthTab()
            storeHandler(actions.REFRESH_SENIOR, false);
        }
    }, [refresh_senior])

    return (
        <div className='d-flex flex-xlg-row'>
            {isWatchAvailable || !!Object.keys(sensorsList)?.length ?
                <div className='flex-grow-1 d-flex flex-column align-item-start mb-3 pr-xlg-0 w-xlg-75'>
                    <div>
                        <Tabs value={tabIndex} indicatorColor='primary' className='indicator-none pb-3 mb-3 border-bottom' variant='scrollable'>
                            {!!Object.keys(sensorsList)?.length && Object.keys(sensorsList)?.map((item, i) => (<Tab key={i} onClick={() => handleSensorChange(i, item)} disableRipple={true} label={<Sensors info={sensorsList[item]} setTabValue={setTabValue} tabsIndex={6 + i} isActive={tabIndex === 6 + i} />} id={`tab ${6 + i}`} />))}
                        </Tabs>
                    </div>
                    {isWatchAvailable &&
                        <div>
                            <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor='primary' className='btn-monitors indicator-none' variant='scrollable'>
                                <Tab disableRipple={true} label={<Monitors name={'Heart Rate'} value={`${watchStatus?.heart_rate !== "00" ? (watchStatus?.heart_rate ?? '-/-') : '-/-'} BPM`} isTabselected={tabIndex === 0} />} id={`tab 1`} />
                                <Tab disableRipple={true} label={<Monitors name={'Blood Pressure'} value={watchStatus?.blood_pressure ? watchStatus?.blood_pressure : ''} isTabselected={tabIndex === 1} />} id={`tab 2`} />
                                <Tab disableRipple={true} label={<Monitors name={'Sleep'} value={`${watchStatus?.sleep !== "00:00" ? (watchStatus?.sleep ?? '--:--') : '--:--'}`} isTabselected={tabIndex === 2} />} id={`tab 3`} />
                                <Tab disableRipple={true} label={<Monitors name={'Steps'} value={`${watchStatus?.step !== "00" ? (watchStatus?.step ?? '--') : '--'}`} isTabselected={tabIndex === 3} />} id={`tab 4`} />
                                <Tab disableRipple={true} label={<Monitors name={'Calories'} value={`${watchStatus?.calories !== "00" ? (watchStatus?.calories ?? '--') : '--'}`} isTabselected={tabIndex === 4} />} id={`tab 5`} />
                                <Tab disableRipple={true} label={<Monitors name={'Blood Oxygen'} value={`${watchStatus?.blood_oxygen !== "00" ? (watchStatus?.blood_oxygen ?? '--') : '--'}`} isTabselected={tabIndex === 5} />} id={`tab 6`} />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0}>
                                <div className='pt-4 h-100 '>
                                    <TabContent name={'Heart Rate'} data={heartRateData} getData={getHeartRateData} tz={tz} />
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <div className='pt-4 h-100'>
                                    <TabContent name={'Blood Pressure'} data={bloodPressureData} getData={getBPData} tz={tz} />
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                <div className='pt-4 h-100'>
                                    <TabContent name={'Sleep'} data={sleepData} getData={getSleepData} tz={tz} />
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                                <div className='pt-4 h-100'>
                                    <TabContent name={'Steps'} data={stepsData} getData={getStepData} tz={tz} />
                                </div>

                            </TabPanel>
                            <TabPanel value={tabIndex} index={4}>
                                <div className='pt-4 h-100'>
                                    <TabContent name={'Calories'} data={caloriesData} getData={getCaloriesData} tz={tz} />
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={5}>
                                <div className='pt-4 h-100'>
                                    <TabContent name={'Blood Oxygen'} data={bloodOxygenData} getData={getOxygenData} tz={tz} />
                                </div>
                            </TabPanel>
                        </div>
                    }
                    {!!Object.keys(sensorsList)?.length && Object.keys(sensorsList).map((name, i) => {
                        const { value, func, name: sensorName } = sensorMapper[name] || {};
                        return <TabPanel key={i} value={tabIndex} index={6 + i}>
                            <div className='pt-4 h-100'>
                                <TabContent name={sensorName} data={value} getData={func} deviceList={sensorsList} loading={loading} tz={tz} />
                            </div>
                        </TabPanel>
                    })}

                </div>
                :
                <div style={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', marginTop: '12%' }}>
                    <span className='mt-100'>No Sensors Connected</span>
                </div>
            }

            <div className={`${styles.sensors} flex-xlg-column d-flex`}>
                <div className='flex-xlg-grow'>
                    <div className={`${styles.batteryIndicator} mb-2 d-flex align-items-center justify-content-between`}>
                        {isWatchAvailable && <div className={`p-3 d-flex align-items-center`}>
                            <img src={battery} alt="battery" className='mr-3' />
                            <div className={`d-flex align-items-center flex-wrap`}>
                                <span className='txt-smd txt-grey-ltr px-2'>
                                    <span className='txt-primary'>
                                        {watchStatus?.battery_level === "101%" ? "( Charging )" : watchStatus?.battery_level === "0%" ? "--:--" : watchStatus?.battery_level + " "}
                                    </span>
                                    Health watch Battery Status
                                </span>
                                <Tooltip title={<div style={{ fontSize: '10px' }}><span>Device Name :</span><span>{watchDetails?.name}</span></div>} placement='top'>
                                    <img style={{ cursor: 'pointer', height: '1rem', width: '1rem', marginRight: '1.5rem' }} src={infoIcon} alt='' />
                                </Tooltip>
                                {watchStatus?.lastupdate &&
                                    <span className='txt-xs txt-light d-flex align-items-center pl-1'>
                                        <img src={clock} alt="clock" className={`${styles.clockIcon} mr-1`} />
                                        <p style={{ paddingTop: '1px' }} >
                                            {timeZone(watchStatus?.lastupdate, watchStatus?.timezone) + " " + (watchStatus?.tz_abbreviation || '')}
                                        </p>
                                    </span>
                                }
                            </div>
                        </div>}
                    </div>

                    {network_type &&
                        <div className={`${styles.batteryIndicator} mb-2 d-flex align-items-center justify-content-between`}>
                            <div className={`p-3 d-flex align-items-center`}>
                                {network_type && network_type === "Wi-Fi" && (
                                    moment().diff(vpn_latest, 'minutes') < 5 && wifi_strength ?
                                        <Tooltip title={wifi_strength}>
                                            <img src={handleCheckWifiStrength(wifi_strength,external_wifi)} alt="wifi" className='mr-3' />
                                        </Tooltip>
                                        :
                                        <Tooltip title={''}>
                                            <img src={wifiOff} alt="wifi" className='mr-3' />
                                        </Tooltip>
                                )}
                                {network_type && network_type === "Wi-Fi" && (
                                    <span style={{ marginRight: "10px" }} className='txt-smd txt-grey-ltr'>Wi-fi Signal Strength </span>
                                )}

                                {network_type && network_type === "LAN" && (
                                    moment().diff(vpn_latest, 'minutes') < 5 ?
                                        <Tooltip title={''}>
                                            <img src={LAN_Connected} alt="LAN" className='mr-3' />
                                        </Tooltip>
                                        :
                                        <Tooltip title={''}>
                                            <img src={LAN_Not_Connected} alt="LAN" className='mr-3' />
                                        </Tooltip>
                                )}
                                {network_type && network_type === "LAN" && (
                                    moment().diff(vpn_latest, 'minutes') < 5 ?
                                        <span style={{ marginRight: "10px" }} className='txt-smd txt-grey-ltr'>Ethernet connected </span>
                                        :
                                        <span style={{ marginRight: "10px" }} className='txt-smd txt-grey-ltr'> Ethernet not connected </span>
                                )}

                            </div>
                        </div>}

                </div>
                {permission?.['cen'] === true && <div className='flex-xlg-grow pl-xlg-3 '>

                    <div className={`${styles.timeLine}`}>
                        <div className={`d-flex justify-content-between ${styles.notifications} px-3 pt-3 pb-2`}>
                            <p className='txt-grey-ltr my-0 d-flex align-items-center'>{moment(selectedDate).parseZone().isSame(moment(), 'day') ? 'Today' : ''}</p>
                            <div className='d-flex align-items-center' >

                                <FilterIcon width={20} height={20} onClick={handleCENFilterOpne} style={{ cursor: 'pointer', marginRight: '15px' }} />
                                <Menu
                                    anchorEl={anchorEl}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    keepMounted
                                    open={CENFilterOpne}
                                    onClose={() => setCENFilterOpen(false)}
                                    className={`${styles.menuSortContainer}`}
                                >
                                    {Object.keys(sensorsList)?.map(item => (
                                        <MenuItem
                                            onClick={() => handleFilterCEN(item)}
                                            selected={CENFilterValue === item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}

                                    {isWatchAvailable && (
                                        <MenuItem
                                            onClick={() => handleFilterCEN('watch')}
                                            selected={CENFilterValue === 'watch'}
                                        >
                                            health watch
                                        </MenuItem>
                                    )}

                                    {Object.keys(sensorsList)?.length > 0 && (
                                        <MenuItem
                                            onClick={() => handleFilterCEN('all')}
                                            selected={CENFilterValue === 'all'}
                                        >
                                            All
                                        </MenuItem>
                                    )}

                                </Menu>

                                <div className={`${styles.customDate} d-flex align-items-center `}>
                                    <DatePicker className={`mt-3 ${styles.customDate}`} inputProps={{ className: 'txt-md', name: 'date' }} value={selectedDate || null} onChange={(val) => handleDateChange(val, tz)} />
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.notifcationList} p-3`}>
                            {/* {notifcation?.length > 0 ? notifcation?.slice(0, 4)?.map((item, i) => <NotificationItems notificationItem={item} noborder={i === 3} />) : <div className={`text-center ${styles.noNotifcation}`}>No Notification</div>} */}
                            {copyNotifcation?.length > 0 ? copyNotifcation?.map((item, i) => <NotificationItems notificationItem={item} noborder={i === 3} />) : <div className={`text-center ${styles.noNotifcation}`}>No Notification</div>}
                        </div>
                    </div>
                </div>}

            </div>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </div>

    )
}

export default HealthTab