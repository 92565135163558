import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import forward from 'assets/images/icon/forward.svg'
import backward from 'assets/images/icon/backward.svg'


export const VideoPreviewer = (props) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady } = props;

    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                player.log('player is ready');
                onReady && onReady(player);
            });
            
            let backwardIcon = document.createElement('img');
            backwardIcon.setAttribute('src',backward);
            backwardIcon.setAttribute('class','fhd-indicator mr-2 cursor');

            backwardIcon.addEventListener('click', () => {
                videoRef.current.currentTime -= 10
            })

            let forwardIcon = document.createElement('img');
            forwardIcon.setAttribute('src',forward);
            forwardIcon.setAttribute('class','fhd-indicator mr-2 cursor');

            forwardIcon.addEventListener('click', () => {
                videoRef.current.currentTime += 10 
            })

            document.getElementsByClassName('vjs-control-bar')[0].appendChild(backwardIcon)
            document.getElementsByClassName('vjs-control-bar')[0].appendChild(forwardIcon)

            // You can update player in the `else` block here, for example:
        } else {
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' />
        </div>
    );
}

export default VideoPreviewer;