import React from 'react';
import { Button } from 'components';
import {
    Dialog,
    DialogActions,
    DialogContentText,
} from '@material-ui/core';
import styles from './confirm-popup.module.scss';
import Seniordetailcard from './Seniordetailcard';

const ConfirmDialog = ({ onClose, open, onConfirm, message, isPrompt, seniorList }) => {

    return (
        <Dialog
            open={open}
            BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
            className={styles.confirmDialog}
            maxWidth={'sm'}>
            <DialogContentText style={{padding:'1rem',marginTop:'-1rem'}}>
                <DialogContentText style={{ color: '#D8D8D8', justifyContent: 'start',fontSize:'1rem',paddingLeft:'1rem',marginTop:'1rem' }} className={styles.dialogContent}>
                    <p className='my-1 txt-medium'>{message}</p>
                </DialogContentText>

                {seniorList?.length > 0 && <Seniordetailcard list={seniorList} />}

                <DialogActions className={styles.footerSec}>
                    {isPrompt ?
                        <Button variant="contained" color="primary" onClick={onConfirm} className={'mx-0'}>
                            Ok
                        </Button> :
                        <>
                            <Button variant="contained" color="primary"  onClick={onConfirm} disableElevation={true}
                             style={{
                                color: '#D8D8D8',
                                margin: '1rem 15px 0 0',
                                width: '40%',
                                whiteSpace: 'noWrap',
                                fontWeight: '300',
                                fontSize: '1.1rem',
                            }} >
                                Yes
                            </Button>
                            <Button variant="contained" color="primary"  
                             style={{
                                color: '#D8D8D8',
                                margin: '1rem 15px 0rem 0',
                                width: '40%',
                                whiteSpace: 'noWrap',
                                fontWeight: '300',
                                fontSize: '1.1rem',
                            }}
                            onClick={() => onClose(false)} >
                                No
                            </Button>
                        </>
                    }

                </DialogActions>
            </DialogContentText>
        </Dialog>
    )
}

export default ConfirmDialog;
