import styles from '../health-tab.module.scss';
import clock from 'assets/images/icon/clock.svg';
import { getIcon } from 'app/dashboard/icons';
import infoIcon from 'assets/images/icon/info-icon.svg';
import moment from 'moment';
import batteryIcon from 'assets/images/icon/battery.svg';
import { Tooltip } from '@material-ui/core';
import { timeZone } from 'utils/validator';

const Badge = ({ device_count }) => {
    return (
        <>
            <span className={`${styles['card-badge']}`}>
                {device_count || '-'}
            </span>
        </>
    )
}




const statusMapper = (name, status, sleepBeltStatus, lastupdate, fall_status) => {
    switch (name) {
        case 'door':
            return status ? 'Open' : 'Closed';
        case 'shower':
            // return status && 'Accessed';
            return 'Accessed';
        case 'pillbox':
            return status ? 'Accessed' : 'Accessed';
        case 'sos':
            return status === true ? 'Triggered' : '';
        case 'sleep_belt_chair':
            return !sleepBeltStatus ? 'Occupied' : 'Vacant';
        case 'sleep_belt':
            return !sleepBeltStatus ? 'Occupied' : 'Vacant';
        case 'FallDetection':
            return fall_status ? fall_status?.split('_')?.join(" ") || '--' : 'Active';
        case 'bathroom':
            return lastupdate ? 'Accessed' : '';
        default:
            return status ? 'Active' : 'Inactive';
    }
}

const Sensors = ({ info, setTabValue, tabsIndex, isActive }) => {
    const { name, date, bool, id, time, battery, vacant, device_name, fall_status, lastupdate, tz_abbreviation = '', timezone } = info['data'] || {};
    const isSleepBelt = name === 'SleepBelt-Chair' || name === 'SleepBelt'
    let sensorName;
    if (isSleepBelt) {
        if (name === 'SleepBelt') {
            sensorName = 'Sleep Belt (Bed)'
        } else if (name === 'SleepBelt-Chair') {
            sensorName = 'Sleep Belt (Chair / Couch)'
        }
    }

    let sensorId;
    switch (id) {
        case 'sos':
            sensorId = 'Urgent Band';
            break;
        case 'sleep_belt':
            sensorId = 'Sleep Belt';
            break;
        case 'sleep_belt_chair':
            sensorId = 'Sleep Belt Chair';
            break;
        default:
            sensorId = id
            break;
    }

    const showTooltip = (name) => {

        if (name === 'FallDetection' && !fall_status) {
            return
        }

        return <img style={{ height: '14px', width: '14px', margin: '0.3rem 0 0 0.3rem', }} src={infoIcon} alt='' />

    }

    return (
        <div className={`${styles.sensorItem} d-flex cursor ${isActive ? styles.activeItem : ''}`} onClick={() => setTabValue(tabsIndex)}>
            <div className={`${styles.sensorImg} d-flex align-items-center justify-content-center p-2`}>
                {getIcon(sensorId, isActive ? '#d8d8d8' : '#00B89F', id === 'sos' && styles.urgentBand)}
            </div>
            <span className='flex-grow-1 d-flex flex-column px-3 py-2 text-left position-relative'>
                {/* <span className={`${styles.indicator} ${bool ? styles.active : styles.inactive}`}></span> */}
                <div className='d-flex flex-row'>
                    <span className='txt-smd txt-grey-ltr d-flex align-items-center justify-content-between'>
                        <span> {name === "FallDetection" ? "Fall Detection" : isSleepBelt ? sensorName : name}</span>

                        <Tooltip title={
                            <div style={{ fontSize: '10px', display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                <span>Device Name : {device_name || '--'}</span>
                                {/* <span>Mac ID : {macid || '--'}</span> */}
                            </div>} placement='top'>
                            <div>
                                {showTooltip(name)}
                            </div>
                        </Tooltip>
                        {![null, undefined, "0%", "", "00%", "0"].includes(battery) ? <span className='d-flex align-items-center'>  <span className='mx-2'></span>{battery && <img src={batteryIcon} className={styles.batteryIcon} alt="battery" />} {battery && <span className='txt-xs txt-primary ml-1'>{battery} %</span>} </span> : name === "FallDetection" ? "" : <span className='txt-xs txt-primary ml-1'>--</span>}
                    </span>
                </div>
                <span className='txt-xs txt-light d-flex align-items-center'>
                    {(lastupdate) && timeZone(lastupdate, timezone) && <img src={clock} alt="clock" className={`${styles.clockIcon} mr-1`} />}
                    {timeZone(lastupdate, timezone) && (timeZone(lastupdate, timezone) + " " + tz_abbreviation || '')}
                </span>
                <span className='d-flex align-items-center'>
                    <span className='txt-xs txt-primary'>
                        {(!(bool === undefined || bool === null)) ?
                            statusMapper(id, bool, vacant, lastupdate, fall_status)
                            :
                            (
                                <div>
                                    <p className='txt-xs txt-primary mt-0 mb-0 pb-0'>Connected</p>
                                    <span className='txt-xs txt-primary'>Fetching Data...</span>
                                </div>
                            )}
                    </span>
                </span>
            </span>

            {info['deviceList']?.length > 1 && <Badge device_count={info['deviceList']?.length} />}
        </div >
    )
}

export default Sensors