import styles from './postlogin.module.scss';
import Header from 'app/header/header';

const PostLogin = ({ children }) => {
    return (
        <div className={`${styles.mainWrapper} p-3 d-flex flex-column`}>
            <Header />
            <div className='py-3 flex-grow-1 d-flex'>
                {children}
            </div>
        </div>
    )
}

export default PostLogin