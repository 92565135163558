import styles from './live-view.module.scss';
import thumbnail from 'assets/images/background/login-bg.png';
import cameraIcon from 'assets/images/icon/camera.svg';
import { useState, useContext, useEffect } from 'react';
import VideoPlayer from './components/video-player';
import { GlobalContext } from 'context';
import LiveVideo from './components/live-video';
import AuthServices from 'api/services/auth-services';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const VideoCard = ({ file, path, size }) => {
    const [showVideo, setShowVideo] = useState(false);
    const videoUrl = path;

    return (
        <div className={`${styles.videoCard} p-3 mr-3 mb-3`}>
            <div className={`${styles.playbackIco} p-2 d-flex flex-column justify-content-between cursor`} onClick={() => setShowVideo(true)}>
                <div>
                    {/* <span>{`03:24`}</span> */}
                </div>
                <div className='text-center'>
                    <span>{file}</span>
                </div>
            </div>
            <img src={thumbnail} alt="stream" className={`${styles.playback}`} />
            {showVideo && <VideoPlayer closePreview={setShowVideo} video={videoUrl} name={file} thumbnail={thumbnail} />}
        </div>
    )
}

const LiveView = ({ seniorId }) => {
    const { state: { userData } } = useContext(GlobalContext)
    const [handleOpenLive, setHandleOpenLive] = useState()

    const { permission } = userData || {}
    const { live_video, view_video } = permission || {}

    const [clips, setClips] = useState([]);

    const [IsliveVideo, setIsLiveVideo] = useState(false)
    const [loading, setLoading] = useState(false);


    const getVideoLink = async () => {
        try {
            setLoading(true);
            const { data: { videos } } = await AuthServices.getVideo(seniorId)

            videos?.forEach(el => {

                if (el != null && el != undefined) {
                    Object.keys(el).includes(seniorId) && el[seniorId]?.live_video && setIsLiveVideo(true)
                }

            })

            await Promise.all(videos?.forEach(async (senior) => {
                if (senior[seniorId]) {
                    // await Promise.all(senior[seniorId]?.clips?.map(async ({ file, path, size }) => {
                    //     const url = await AuthServices.getMotionClip(path)
                    //     const clipList = clips
                    //     clipList.push({
                    //         file,
                    //         path: url,
                    //         size
                    //     })
                    //     console.log(clipList)
                    //     setClips(clipList)
                    // }))

                    const groupByDate = senior[seniorId]?.clips?.reduce((group, { file, path, size, date }) => {
                        // console.log(group);
                        // console.log(file);
                        // console.log(path);
                        // console.log(size);
                        // console.log(date);

                        let videoDate = new Date(date)

                        const currentDate = new Date()

                        const today = moment(currentDate).isSame(moment(videoDate), 'days')
                        const yesterday = moment(videoDate).isSame(moment().subtract(1, 'day'), 'days')

                        const groupDate = today ? "Today" : yesterday ? "Yesterday" : moment(videoDate).format("MMM DD, yyyy")

                        group[groupDate] = group[groupDate] ?? [];

                        group[groupDate].push({ file, path, size, date });

                        return group;

                    }, {});

                    // setClips(senior[seniorId]?.clips)
                    setClips(groupByDate);
                }
            }))
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getVideoLink()
    }, [])

    return (
        <div>
            <div className={`${styles.liveViewWrapper} p-3 mb-3`}>
                <p className='txt-grey-lt mt-0 txt-xl text-center'>Live View</p>
                <div>
                    <div className='text-center'>
                        {loading && <CircularProgress />}
                        {loading && <p>Loading Camera</p>}
                    </div>
                    {!loading && (
                        IsliveVideo ? (
                            live_video ? <div onClick={() => setHandleOpenLive(true)} className={`${styles.liveViewCard} cursor mb-3 d-flex flex-column`}>
                                <div className={`${styles.liveViewCardImg} flex-grow-1 d-flex align-items-center justify-content-center`}>
                                    <img src={cameraIcon} alt="camera" />
                                </div>
                                <div className={`${styles.livingRoom} p-3 text-center`}>
                                    Living Room
                                </div>
                            </div> : <div className='text-center py-4'>You dont have permission to view this content</div>
                        ) : <div className='text-center pb-4'>There are no active live streams</div>
                    )}

                </div>
            </div>
            <div className={`${styles.liveViewWrapper} p-3`}>
                <p className='txt-grey-lt mt-0 txt-xl text-center'>Motion Clips</p>
                <div className='text-center'>
                    {loading && <p>Loading Motion Clips</p>}
                    {loading && <CircularProgress />}
                </div>
                {!loading && Object.keys(clips).length == 0 ? <div className='text-center py-4'>There are no motion clips</div> : (
                    view_video ? !!Object.keys(clips).length && Object.keys(clips).map(clipsData => (
                        <>
                            <p>{clipsData}</p>
                            <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
                                {clips[clipsData]?.map(({ file, path, size, date }, index) => <VideoCard key={index} file={file} path={path} size={size} />)}
                            </div>
                        </>

                    ))?.reverse()
                        : <div className='text-center py-4'>You dont have permission to view this content</div>
                )}
            </div>
            <LiveVideo open={handleOpenLive} handleClose={() => setHandleOpenLive(false)} seniorId={seniorId} />
        </div >
    )
}

export default LiveView