import { useState } from 'react';
import styles from '../health-tab.module.scss';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Legend, Tooltip, BarChart, Bar, ReferenceLine } from 'recharts';
import { Tab, Tabs, DatePicker, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Menu, MenuItem, Box, } from 'components';
import { Checkbox, CircularProgress, ListItemText, TableFooter, TablePagination, Tooltip as MUITooltip } from "@material-ui/core";
import { ReactComponent as FilterIcon } from '../../../../assets/images/icon/filter_icon.svg';
import { ReactComponent as SortIcon } from '../../../../assets/images/icon/sort_icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { format, isValid } from 'date-fns'
import { useEffect } from 'react';
import { TablePaginationActions } from 'components/table-pagination/table-pagination-actions';
import FallDetectionStepper from './FallDetectionStepper'
import { ReactComponent as ListIcon } from 'assets/images/icon/list_box_outline.svg'
import infoIcon from 'assets/images/icon/info-icon.svg';
import battery from 'assets/images/icon/battery.svg';
import { RPI_timezone, timeZone } from 'utils/validator';
import momentTZ from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
    selectedTab: {
        color: "white",
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 184, 159, .2)',
            color: 'white',
        },
    },
}));

const classMapper = {
    'Heart Rate': 'heart',
    'Blood Pressure': 'bp',
    'Blood Oxygen': 'spo2',
    'Steps': 'steps',
    'Sleep': 'sleep',
    'Calories': 'calories',
    // 'PillBox Sensor': 'pilllbox',
    // 'Door Sensor': 'door',
    'Shower': 'shower'

}
const timielineMapper = {
    0: 'day',
    1: 'weekly',
    2: 'monthly',
    3: 'yearly'
}

const sensorNameMaper = (name) => {

    switch (name) {
        case 'Urgent Band':
            return 'SOS'
        case 'Sleep Belt (Chair / Couch)':
            return 'SleepBelt-Chair'
        case 'Sleep Belt (Bed)':
            return 'SleepBelt'
        default:
            return name
    }

}

const getIsRange = (data) => {
    for (let x = 0; x < data.length; x++) {
        let keys = Object.keys(data[0])
        if (keys?.length > 1) {
            return true
        }
    }
    return false
}

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props || {};
    const { value } = payload || {}

    const getValue = (val) => {
        if (typeof val === 'string') {
            let value = val.split(':')
            let axis = value[0] ? value[0] : '';
            axis = axis + (value[1] ? `:${value[1]}` : '')
            return axis
        }
        return val
    }
    return (
        <g transform={`translate(${x + 5},${y})`}>
            <text
                // x={20}
                // y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform='rotate(-35)'
            >
                {getValue(value)}
            </text>
        </g>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label my-0">{`${label}`}</p>
                <p className="label my-0 mt-1 txt-primary">{`Maximum : ${payload[0]?.payload.max}`}</p>
                <p className="label my-0 mt-1 txt-primary">{`Minimum: ${payload[0]?.payload.min}`}</p>
                <p className="label my-0 mt-1 txt-primary">{`Average: ${payload[0]?.payload.avg}`}</p>
            </div>
        );
    }

    return null;
};

const CustomGraphTooltip = ({ active, payload, label }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip">
                <p className="label my-0">{`Time: ${label}`}</p>
                <p className="label my-0 mt-1 txt-primary">{`Value : ${payload[0]?.payload?.value}`}</p>
            </div>
        );
    }

    return null;
}

const CustomBPTooltip = ({ active, payload, label, tabnum }) => {
    if (active && payload && payload.length) {
        const [data] = payload || []
        const { payload: values } = data || {}
        if (tabnum == 0) {
            const { Diastolic, Systolic } = values || {}
            return (
                <div className="custom-tooltip">
                    <p className="label my-0 t">{`Time: ${label}`}</p>
                    <p className="label my-0 mt-1" style={{ color: '#ff4967' }}>{`Avg. Systolic : ${Systolic}`}</p>
                    <p className="label my-0 mt-1" style={{ color: '#8884d8' }}>{`Avg. Diastolic: ${Diastolic}`}</p>
                </div>
            );
        }
        else {

            const { avg_dia, avg_sys, max_dia, min_dia, max_sys, min_sys } = values || {}

            return (
                <div className="custom-tooltip">
                    <p className="label my-0 t">{`${label}`}</p>
                    <p className="label my-0 mt-1" style={{ color: '#ff4967' }}>{`Avg. Systolic : ${avg_sys}`}</p>
                    <p className="label my-0 mt-1" style={{ color: '#8884d8' }}>{`Avg. Diastolic: ${avg_dia}`}</p>
                </div>
            );

        }

    }

    return null;
};

const DailyGraph = ({ data, isBloodPressure, tabnum, basedata, graphScale }) => {

    const { domain, refLine, [tabnum]: { lable }, tickCount, name } = basedata() || {}
    const { xAxis } = graphScale[tabnum]
    data = xAxis(data, 1, domain[0])
    if (domain[1] === 'auto' && !getIsRange(data)) domain[1] = 100

    const [height, setHeight] = useState(600)

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {

            if (window.innerWidth > 1365) {
                setHeight(600)
            } else if (window.innerWidth > 991) {
                setHeight(400)
            } else {
                setHeight(300)
            }
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    });

    return (<ResponsiveContainer width="100%" height={height}>
        <LineChart data={data || []} margin={{ top: 20, right: 30, left: 10, bottom: 40 }}>
            {!isBloodPressure ? <Line type="monotone" dataKey={'value'} stroke="#00B89F" isAnimationActive={false} dot={{ stroke: '00B89F', strokeWidth: 1, r: 4, strokeDasharray: '' }} /> : (
                <>
                    <Line type="monotone" dataKey={'Systolic'} isAnimationActive={false} stroke="#00B89F" dot={{ stroke: '00B89F', strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                    <Line type="monotone" dataKey={'Diastolic'} isAnimationActive={false} stroke="#8884d8" dot={{ stroke: '00B89F', strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                </>
            )}
            <ReferenceLine type='monotone' dot={false} y={refLine["max"]} stroke="#FF0000" />
            <ReferenceLine type='monotone' dot={false} y={refLine["min"]} stroke="#0000FF" />

            <XAxis dataKey="time" tick={<CustomizedAxisTick />} label={{ value: lable["x"], position: "bottom", offset: 20, stroke: "#00B89F" }} />
            <YAxis dataKey={'value'} domain={domain} tickCount={tickCount} label={{ value: lable["y"], angle: -90, position: 'insideLeft', stroke: "#00B89F" }} />
            {<Tooltip content={isBloodPressure ? <CustomBPTooltip tabnum={tabnum} /> : <CustomGraphTooltip tabnum={tabnum} />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />}
            {isBloodPressure && <Legend verticalAlign="top" height={36} />}
        </LineChart>
    </ResponsiveContainer>)

}
const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;
    const mapper = { 'max_min_sys': 'Systolic', 'max_min_dia': 'Diastolic' }
    return <span style={{ color }}>{mapper[value]}</span>;
};

const WeeklyGraph = ({ data, isBloodPressure, isSensor, day, isSleepData, isAnnualData, tabnum, basedata, graphScale }) => {
    const [height, setHeight] = useState(600)
    const { domain, overflow, refLine, [tabnum]: { lable }, tickCount } = basedata() || {}
    if (domain[1] === 'auto' && !getIsRange(data)) domain[1] = 100

    const { xAxis } = graphScale[tabnum]
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {

            if (window.innerWidth > 1365) {
                setHeight(600)
            } else if (window.innerWidth > 991) {
                setHeight(400)
            } else {
                setHeight(300)
            }
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    if (isBloodPressure) {
        const graphData = tabnum == 2 ?
            data.map((item, i) => ({
                ...item, date: i + 1,
                max_min_sys: item.max_min_sys === 0 && item.min_sys !== 0 && item.max_sys !== 0 ? (item.max_min_sys + 2) : item.max_min_sys,
                max_min_dia: item.max_min_dia === 0 && item.min_dia !== 0 && item.max_dia !== 0 ? (item.max_min_dia + 2) : item.max_min_dia,

            }))
            :
            data.map(item => ({
                ...item,
                max_min_sys: item.max_min_sys === 0 && item.min_sys !== 0 && item.max_sys !== 0 ? (item.max_min_sys + 2) : item.max_min_sys,
                max_min_dia: item.max_min_dia === 0 && item.min_dia !== 0 && item.max_dia !== 0 ? (item.max_min_dia + 2) : item.max_min_dia,

            }))
        return <ResponsiveContainer width="100%" height={height}>
            <BarChart data={graphData} barSize={[10, 10]} margin={{ top: 20, right: 30, left: 10, bottom: 30 }}>
                <XAxis dataKey="date" label={{ value: lable["x"], position: "bottom", offset: 10, stroke: "#00B89F" }} />
                <YAxis domain={domain} allowDataOverflow={overflow} tickCount={tickCount} label={{ value: lable["y"], angle: -90, position: 'insideLeft', stroke: "#00B89F" }} />
                <Tooltip content={isBloodPressure ? <CustomBPTooltip tabnum={tabnum} /> : <CustomGraphTooltip tabnum={tabnum} />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />
                <Legend verticalAlign="top" height={36} formatter={renderColorfulLegendText} />
                <ReferenceLine type='monotone' dot={false} y={refLine["max"]} stroke="#FF0000" />
                <ReferenceLine type='monotone' dot={false} y={refLine["min"]} stroke="#0000FF" />
                <Bar dataKey="min_sys" stackId="a" fill="transparent" barSize={40} />
                <Bar dataKey='max_min_sys' stackId="a" fill="#00B89F" barSize={40} />
                <Bar dataKey="min_dia" stackId="b" fill="transparent" barSize={40} />
                <Bar dataKey="max_min_dia" stackId="b" fill="#6C6AB9" barSize={40} />

            </BarChart>
        </ResponsiveContainer>
    } else if (isSensor) {
        return <ResponsiveContainer width="100%" height={height} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <BarChart data={data} barSize={[10, 10]}>
                <XAxis dataKey={day ? 'time' : "date"} tickCount={15} />
                {day ? <YAxis tickCount={2} /> : <YAxis />}
                <Bar dataKey="value" fill="#00B89F" barSize={60} />
                <Tooltip content={isBloodPressure ? <CustomBPTooltip tabnum={tabnum} /> : <CustomGraphTooltip />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />
            </BarChart>
        </ResponsiveContainer>
    } else if (isSleepData && !isAnnualData) {

        const graphData = tabnum == 2 ?
            data.map((item, i) => ({ ...item, date: i + 1 }))
            :
            data
        return <ResponsiveContainer width="100%" height={height} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <BarChart data={graphData} barSize={[10, 10]} margin={{ top: 20, right: 30, left: 10, bottom: 30 }}>
                <XAxis dataKey="date" label={{ value: lable["x"], position: "bottom", offset: 10, stroke: "#00B89F" }} />
                <YAxis domain={domain} allowDataOverflow={overflow} tickCount={tickCount} label={{ value: lable["y"], angle: -90, position: 'insideLeft', stroke: "#00B89F" }} />
                <Bar dataKey="value" stackId="a" fill="#00B89F" barSize={60} />
                <ReferenceLine type='monotone' dot={false} y={refLine["max"]} stroke="#FF0000" />
                <ReferenceLine type='monotone' dot={false} y={refLine["min"]} stroke="#0000FF" />
                <Tooltip content={isBloodPressure ? <CustomBPTooltip tabnum={tabnum} /> : <CustomGraphTooltip />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />
            </BarChart>
        </ResponsiveContainer>
    } else {
        const graphData = tabnum == 2 ?
            data.map((item, i) => ({ ...item, date: i + 1, max_min: item.max_min === 0 && item.min !== 0 && item.max !== 0 ? (item.max_min + 2) : item.max_min }))
            :
            data.map(item => ({ ...item, max_min: item.max_min === 0 && item.min !== 0 && item.max !== 0 ? (item.max_min + 2) : item.max_min }))
        return <ResponsiveContainer width="100%" height={height} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <BarChart data={graphData} barSize={[10, 10]} margin={{ top: 20, right: 30, left: 10, bottom: 30 }}>
                <XAxis dataKey="date" label={{ value: lable["x"], position: "bottom", offset: 10, stroke: "#00B89F" }} />
                <YAxis domain={domain} allowDataOverflow={overflow} tickCount={tickCount} label={{ value: lable["y"], angle: -90, position: 'insideLeft', stroke: "#00B89F" }} />
                <Bar dataKey="min" stackId="a" fill="transparent" barSize={60} />
                <Bar dataKey="max_min" stackId="a" fill="#00B89F" barSize={60} />
                <ReferenceLine type='monotone' dot={false} y={refLine["max"]} stroke="#FF0000" />
                <ReferenceLine type='monotone' dot={false} y={refLine["min"]} stroke="#0000FF" />
                <Tooltip content={isBloodPressure ? <CustomBPTooltip /> : <CustomTooltip />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />
            </BarChart>
        </ResponsiveContainer>
    }

}

const AnnualGraph = ({ data }) => {

    const [height, setHeight] = useState(600)

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {

            if (window.innerWidth > 1365) {
                setHeight(600)
            } else if (window.innerWidth > 991) {
                setHeight(400)
            } else {
                setHeight(300)
            }
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    return (<ResponsiveContainer width="100%" height={height} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
        <BarChart data={data} barSize={[10, 10]}>
            <XAxis dataKey="date" tickCount={15} />
            <YAxis />
            <Bar dataKey="min" stackId="a" fill="transparent" barSize={60} />
            <Bar dataKey="max" stackId="a" fill="#00B89F" barSize={60} />
            <Tooltip content={<CustomTooltip />} contentStyle={{ backgroundColor: '#272E36', boxShadow: '0px 0px 0.5rem #00000040', border: 'none', borderRadius: '0.5rem' }} />
        </BarChart>
    </ResponsiveContainer>)

}

const TabContent = ({ name, data, getData, deviceList, loading, tz }) => {
    const [heartRate, setHeartRate] = useState({
        name: "Heart Rate",
        overflow: true,
        domain: [0, 120],
        refLine: {
            max: 100,
            min: 60
        },
        tickCount: null,
        0: {
            lable: {
                x: "Time",
                y: "BPM"
            }
        },
        1: {
            lable: {
                x: "Days",
                y: "BPM"
            }
        },
        2: {
            lable: {
                x: "Days",
                y: "BPM"
            }
        },
        3: {
            lable: {
                x: "Months",
                y: "BPM"
            }
        }
    })

    const [pressure, setPressure] = useState({
        name: "BP",
        overflow: true,
        domain: [0, 200],
        refLine: {
            max: 130,
            min: 80
        },
        tickCount: null,
        0: {
            lable: {
                x: "Time",
                y: "BP"
            }
        },
        1: {
            lable: {
                x: "Days",
                y: "BP"
            }
        },
        2: {
            lable: {
                x: "Days",
                y: "BP"
            }
        },
        3: {
            lable: {
                x: "Months",
                y: "BP"
            }
        }
    })

    const [sleep, setSleep] = useState({
        name: "Sleep",
        overflow: true,
        domain: [0, 15],
        refLine: {
            max: null,
            min: null
        },
        tickCount: 16,
        0: {
            lable: {
                x: "Time",
                y: "HRS"
            }
        },
        1: {
            lable: {
                x: "Days",
                y: "HRS"
            }
        },
        2: {
            lable: {
                x: "Days",
                y: "HRS"
            }
        },
        3: {
            lable: {
                x: "Months",
                y: "HRS"
            }
        }
    })

    const [step, setStep] = useState({
        name: "Steps",
        overflow: false,
        domain: [0, 'auto'],
        refLine: {
            max: null,
            min: null
        },
        tickCount: null,
        0: {
            lable: {
                x: "",
                y: ""
            }
        },
        1: {
            lable: {
                x: "",
                y: ""
            }
        },
        2: {
            lable: {
                x: "",
                y: ""
            }
        },
        3: {
            lable: {
                x: "",
                y: ""
            }
        }
    })

    const [calorie, setCalorie] = useState({
        name: "Calorie",
        overflow: true,
        domain: [0, 100],
        refLine: {
            max: null,
            min: null
        },
        tickCount: null,
        0: {
            lable: {
                x: "",
                y: ""
            }
        },
        1: {
            lable: {
                x: "",
                y: ""
            }
        },
        2: {
            lable: {
                x: "",
                y: ""
            }
        },
        3: {
            lable: {
                x: "",
                y: ""
            }
        }
    })

    const [oxygen, setOxygen] = useState({
        name: "SPO2",
        overflow: true,
        domain: [40, 100],
        refLine: {
            max: null,
            min: 95
        },
        tickCount: null,
        0: {
            lable: {
                x: "Time",
                y: "SPO2"
            }
        },
        1: {
            lable: {
                x: "Days",
                y: "SPO2"
            }
        },
        2: {
            lable: {
                x: "Days",
                y: "SPO2"
            }
        },
        3: {
            lable: {
                x: "Months",
                y: "SPO2"
            }
        }
    })

    const [graphScale, setGraphScale] = useState({
        0: {
            xAxis: (data, space = 1, minValue = 0) => {
                let arr = []
                for (let x = 0; x < 24; x++) {
                    if (x % space !== 0) arr.push({ ...data[x], time: " " })
                    else if (x === 0) arr.push({ ...data[x], time: "12AM" })
                    else if (x === 12) arr.push({ ...data[x], time: "12PM" })
                    else if (x < 12) arr.push({ ...data[x], time: x + "AM" })
                    else arr.push({ ...data[x], time: (x - 12) + "PM" })
                }

                arr.forEach(el => {

                    if (el?.value < minValue || el?.value === 0) delete el.value

                    if (el?.Diastolic < minValue || el?.Diastolic === 0) delete el.Diastolic

                    if (el?.Systolic < minValue || el?.Systolic === 0) delete el.Systolic
                })

                return arr
            }
        },
        1: {
            xAxis: (data) => {
            }
        },
        2: {
            xAxis: (data, limit = 1) => {
            }
        },
        3: {
            xAxis: (data) => {
            }
        }
    })
    const classes = useStyles();
    const [currentSelectedSensor, setCurrentSelectedSensor] = useState(0)
    const [selectedDate, setSelectedDate] = useState(RPI_timezone(tz.timezone));
    const [tabIndex, setTabValue] = useState(0);
    const handleDateChange = (date) => {
        date = RPI_timezone(tz.timezone, date)
        setTabValue(0)
        setSelectedDate(date);
        const updated_name = sensorNameMaper(name)
        getData(timielineMapper[0], date, deviceList[updated_name]['deviceList'][currentSelectedSensor]['mac_id']);
    };
    const isBloodPressure = name === 'Blood Pressure';

    const isSleepData = name === 'Sleep';

    const isSensor = false;
    const isSensorTable = name === 'PillBox' || name === 'Door' || name === 'Shower' || name === 'Urgent Band' || name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)' || name === 'FallDetection' || name === "BathRoom";

    useEffect(() => {
        if (tabIndex === 0 && data) {
            if (isSensorTable) {
                const updated_name = sensorNameMaper(name)
                getData(timielineMapper[0], selectedDate, deviceList[updated_name]['deviceList'][currentSelectedSensor]['mac_id']);
            } else {
                getData(timielineMapper[0], name);
            }
        }
    }, [])

    const handleTabChange = (event, newValue,) => {
        setTabValue(newValue);
        setSelectedDate(RPI_timezone(tz.timezone))
        if (isSensorTable) {
            const updated_name = sensorNameMaper(name)
            getData(timielineMapper[newValue], selectedDate, deviceList[updated_name]['deviceList'][currentSelectedSensor]['mac_id']);
        } else {
            getData(timielineMapper[newValue]);
        }
        setPage(0)
        setRowsPerPage(newValue === 1 ? 7 : 5)
        // getData(timielineMapper[newValue]);

    };

    const handleCurrentSelectedSensor = (ind) => {
        setCurrentSelectedSensor(ind)
        if (isSensorTable) {
            const updated_name = sensorNameMaper(name)
            getData(timielineMapper[tabIndex], selectedDate, deviceList[updated_name]['deviceList'][ind]['mac_id']);
        } else {
            getData(timielineMapper[tabIndex], name);
        }
    }

    const [openSortType, setOpenSortType] = useState(null);
    const [openFilterType, setOpenFilterType] = useState(null);
    const [openFallDeviceList, setOpenFallDeviceList] = useState(null);
    const [filterData, setFilterData] = useState({ Open: false, Closed: false, All: false });
    const [tableData, setTableData] = useState([]);
    const [tableFiltedData, setTableFilteredData] = useState([]);
    const [copyTableFiltedData, setCopyTableFiltedData] = useState([]);
    const [sortNameToggle, setSortNameToggle] = useState(false);
    const [sortTimeToggle, setSortTimeToggle] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [fallDetectionData, setFallDetectionData] = useState({
        device_selected: '',
        is_sorted: '',
        filter_selected: ''
    });
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableFiltedData?.length - page * rowsPerPage);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {

        if (name === 'FallDetection') {
            setTableData(data?.data);
            setTableFilteredData(data?.data?.length > 0 && data?.data);
            setCopyTableFiltedData(data?.data?.length > 0 && data?.data)
        } else {
            setTableData(data?.data?.reverse());
            setTableFilteredData(data?.data?.length > 0 && tabIndex === 0 ? data?.data?.sort() : data?.data?.sort().reverse());
            setCopyTableFiltedData(data?.data?.length > 0 ? data?.data.sort().reverse() : data?.data)
        }

        setFallDetectionData({ device_selected: '', is_sorted: '', filter_selected: '' })

    }, [data?.data, name]);

    const getTable = () => {

        const handleFallDeviceList = (e) => {
            setOpenFallDeviceList(e.currentTarget);
        };
        const handleFallDeviceClose = () => {
            setOpenFallDeviceList(null);
        };
        const handleFilterClick = (e) => {
            setOpenFilterType(e.currentTarget);
        };
        const handleSortClick = (e) => {
            setOpenSortType(e.currentTarget);
        }
        const handleSortClose = () => {
            setOpenSortType(null);
        }
        const handleFilterClose = () => {
            setOpenFilterType(null);
        };

        const handleGetFallData = (macID) => {


            if (macID === 'all') {
                setTableFilteredData([...copyTableFiltedData])
                setFallDetectionData({ device_selected: 'get_all', filter_selected: '', is_sorted: '', })
                handleFallDeviceClose(null)
                return
            }

            const newFilteredData = copyTableFiltedData?.filter(item => {
                if (item?.macid === macID) {
                    return item
                }
            })

            setFallDetectionData({ device_selected: macID, filter_selected: '', is_sorted: '', })
            setTableFilteredData(newFilteredData)
            handleFallDeviceClose(null)

        }

        const fallDetectionFilterSort = (list) => {

            if (fallDetectionData['is_sorted'] === '') {
                return list
            } else {

                let notFallDetectedList = []
                const newFilteredData = list?.filter(item => {
                    if (item?.fall_detected) {
                        return item
                    } else {
                        notFallDetectedList.push(item)
                    }
                })?.sort((a, b) => {
                    return moment(a.fall_detected, 'DD-MM-YYYY hh:mm:ss A').isBefore(moment(b.fall_detected, 'DD-MM-YYYY hh:mm:ss A')) ? -1 : 1
                })

                return fallDetectionData['is_sorted'] ? [...notFallDetectedList, ...newFilteredData] : [...newFilteredData.reverse(), ...notFallDetectedList]

            }
        }

        const filterFallDetectionData = (name) => {

            if (name === "All") {

                if (fallDetectionData['device_selected'] === 'get_all' || fallDetectionData['device_selected'] === '') {
                    setTableFilteredData(fallDetectionFilterSort([...copyTableFiltedData]))
                } else {
                    let filteredList = copyTableFiltedData?.filter(item => item?.macid === fallDetectionData['device_selected'])
                    setTableFilteredData(fallDetectionFilterSort([...filteredList]))
                }

                setFallDetectionData({ ...fallDetectionData, filter_selected: 'filter_all' })
                setOpenFilterType(null);
                return

            }

            const newFilteredData = copyTableFiltedData?.filter(item => {
                if (item?.[name] && (item?.macid === fallDetectionData['device_selected'] || fallDetectionData['device_selected'] === '' || fallDetectionData['device_selected'] === "get_all")) {
                    return item
                }
            })
            setFallDetectionData({ ...fallDetectionData, filter_selected: name })
            setTableFilteredData(fallDetectionFilterSort(newFilteredData))
            setOpenFilterType(null);

        }

        const sortFallDetectionData = () => {

            let notFallDetectedList = []
            const newFilteredData = tableFiltedData?.filter(item => {
                if (item?.fall_detected) {
                    return item
                } else {
                    notFallDetectedList.push(item)
                }
            })?.sort((a, b) => {
                return moment(a.fall_detected, 'DD-MM-YYYY hh:mm:ss A').isBefore(moment(b.fall_detected, 'DD-MM-YYYY hh:mm:ss A')) ? -1 : 1
            })

            fallDetectionData['is_sorted'] ?
                setTableFilteredData([...newFilteredData.reverse(), ...notFallDetectedList])
                :
                setTableFilteredData([...notFallDetectedList, ...newFilteredData])

            setOpenSortType(null);
            setFallDetectionData({ ...fallDetectionData, is_sorted: !fallDetectionData['is_sorted'] })

        }

        const handleToggle = (selectedVal, value) => {
            const filterObj = filterData
            Object.keys(filterData).forEach((filter) => {
                filterObj[filter] = filter === selectedVal
            })
            setFilterData(filterObj)
            Object.keys(filterObj).forEach((filter) => {
                if (filterObj[filter] === true) {
                    switch (filter) {
                        case 'Open':
                            const filterOpenedData = tableData.filter((item) => item.value === true)
                            setTableFilteredData(filterOpenedData)
                            handleFilterClose()
                            break
                        case 'Closed':
                            const filterClosedData = tableData.filter((item) => item.value === false)
                            setTableFilteredData(filterClosedData)
                            handleFilterClose()
                            break
                        case 'All':
                            setTableFilteredData(tableData)
                            handleFilterClose()
                            break
                        default: break
                    }
                }
            })
        };

        const handleSortToggle = (selectedVal) => {
            if (selectedVal === "Status") {
                const newToggle = !sortNameToggle;
                setSortNameToggle(newToggle);
                const sortedData = newToggle ? tableFiltedData.sort((a, b) => (a.value - b.value)) : tableFiltedData.sort((a, b) => (a.value - b.value)).reverse();
                setTableFilteredData(sortedData);
                handleSortClose();
            }
            else if (selectedVal === "Time") {
                const newToggle = !sortTimeToggle;
                setSortTimeToggle(newToggle);
                const sortedData = tableFiltedData.sort().reverse();
                setTableFilteredData(sortedData);
                handleSortClose();
            }
        }

        const getTableCellVal = (name, value) => {
            let val;
            switch (name) {
                case 'Urgent Band': val = 'Triggered'
                    break
                case 'PillBox': val = 'Accessed'
                    break
                case 'Shower': val = 'Accessed'
                    break
                case 'FallDetection': val = 'Detected'
                    break
                case 'Sleep Belt (Bed)':
                    val = value ? 'Vacant' : 'Occupied'
                    break;
                case 'Sleep Belt (Chair / Couch)':
                    val = value ? 'Vacant' : 'Occupied'
                    break;
                case 'BathRoom': val = 'Accessed';
                    break;
                default: val = value ? 'Open' : 'Closed'
            }
            return val
        }

        const nowDate = moment(new Date()).format('YYYY-MM-DD');
        const getHrSufix = (val) => {
            if (!val) return ''
            return val < 2 ? 'hr' : 'hr(s)'
        }
        const getMinSufix = (val) => {
            if (!val) return ''
            return val < 2 ? 'min' : 'min(s)'
        }
        const getSecSufix = (val) => {
            if (name === 'BathRoom') {
                if (!val) return ''
                return val < 2 ? 'sec' : 'sec(s)'
            }
        }
        const formattedData = (data) => {
            if (!data || data.length < 1) return []
            if (timielineMapper[tabIndex] === 'day') {
                const formattedValue = data.map(item => {
                    if (name === "BathRoom") {
                        //const formattedDate = `${isValid(new Date(item?.date)) ? (moment(new Date(`${item?.date}`)).format('hh:mm:ss A')) : '-'}  ${isValid(new Date(item.end_date)) ? `- ${(moment(new Date(`${item?.end_date}`)).format('hh:mm:ss A'))}` : ''}`
                        const formattedDate = `${isValid(new Date(item?.date)) ? timeZone(item?.date, "", 'hh:mm:ss A') : '-'}  ${isValid(new Date(item.end_date)) ? `- ${timeZone(item?.end_date, "", 'hh:mm:ss A')}` : ''}`
                        const [hrs, min, sec] = item?.duration?.split(':') || '';
                        const duration = `${Number(hrs || '') ? hrs : ''} ${getHrSufix(Number(hrs || ''))} ${Number(min || '') ? min : ''} ${getMinSufix(Number(min || ''))} ${Number(sec || '') ? sec : '-'} ${getSecSufix(Number(sec || ''))}`
                        return {
                            time: formattedDate,
                            value: getTableCellVal(name, item.value),
                            duration
                        }
                    }

                    else {
                        //This is for local time 
                        const formattedDate = `${moment(`${item?.date} ${item?.time}`).isValid() ? moment.utc(`${item?.date} ${item?.time}`).local().format("hh:mm A") : "-"} ${moment(`${item?.end_date} ${item?.end_time}`).isValid() ? '- ' + moment.utc(`${item?.end_date} ${item?.end_time}`).local().format("hh:mm A") : ''}`
                        // const formattedDate = `${moment(`${item?.date} ${item?.time}`).isValid() ? moment(`${item?.date} ${item?.time}`).format("hh:mm A") : "-"} ${moment(`${item?.end_date} ${item?.end_time}`).isValid() ? '- ' + moment(`${item?.end_date} ${item?.end_time}`).format("hh:mm A") : ''}`
                        const [hrs, min] = item?.duration?.split(':') || ''
                        const duration = `${Number(hrs || '') ? hrs : ''} ${getHrSufix(Number(hrs || ''))} ${Number(min || '') ? min : '-'} ${getMinSufix(Number(min || ''))}`
                        return {
                            time: formattedDate,
                            value: getTableCellVal(name, item?.value),
                            duration
                        }
                    }
                })

                //This is for local time
                if (name === 'FallDetection') {
                    if (data?.length > 0) {
                        const fallData = data[0]
                        fallData?.calling && (fallData['calling'] = timeZone(fallData['calling']))
                        fallData?.fall_confirmed && (fallData['fall_confirmed'] = timeZone(fallData['fall_confirmed']))
                        fallData?.fall_detected && (fallData['fall_detected'] = timeZone(fallData['fall_detected']))
                        return [fallData]
                    }
                }
                // if (name === 'FallDetection') return data

                return formattedValue
            } else {
                const formattedValue = data.map(item => {
                    let value = ''
                    let time;
                    let status;
                    if (name === "Sleep Belt (Bed)" || name === "Sleep Belt (Chair / Couch)") {
                        const [occHrs, occMin] = item?.week_count?.false?.split(':') || '';
                        const [vacHrs, vacMin] = item?.week_count?.true?.split(':') || '';
                        const occDuration = `${Number(occHrs || '') ? occHrs : ''} ${getHrSufix(Number(occHrs || ''))} ${Number(occMin || '') ? occMin : '-'} ${getMinSufix(Number(occMin || ''))}`
                        const vacDuration = `${vacHrs || '-'} hr(s) ${vacMin || '-'} min(s)`
                        value = ` ${occDuration}`

                    } else if (name === "BathRoom") {
                        const [hrs, min, sec] = item?.week_count?.[0]?.duration.split(':') || '';
                        const duration = `${Number(hrs || '') ? hrs : ''} ${getHrSufix(Number(hrs || ''))} ${Number(min || '') ? min : ''} ${getMinSufix(Number(min || ''))} ${Number(sec || '') ? sec : '-'} ${getSecSufix(Number(sec || ''))}`
                        value = (hrs === undefined && min === undefined && sec === undefined) ? '-' : `${duration}`
                        status = item?.week_count?.length > 0 ? `Accessed(${item?.week_count?.[0]?.count})` : '-'
                    }
                    else {
                        value = (item?.week_count?.length > 0) ? item?.week_count?.map(({ count, value }) => {
                            let status = value ? getTableCellVal(name, value) : getTableCellVal(name, value)
                            return `${status} (${count}) `
                        }) : '-';
                    }
                    if (timielineMapper[tabIndex] === 'weekly') {
                        time = item?.start_date ? timeZone(item?.start_date, "", 'MMM Do ( dddd )') : '-'
                        //time = item?.end_date ? format(new Date(item.end_date), 'LLL do ( EEEE )') : '-'
                    } else if (timielineMapper[tabIndex] === 'monthly') {
                        // time = item?.end_date ? format(new Date(item.end_date), 'LLLL do') : '-'
                        time = item?.start_date ? timeZone(item?.start_date, '', 'MMMM Do') : '-'
                    }
                    if (name === 'FallDetection') {
                        let fallData = { ...item }
                        if (fallData?.week_count?.length > 0) {
                            let weekCount = { ...fallData?.week_count[0] }
                            weekCount?.calling && (weekCount['calling'] = timeZone(weekCount['calling']))
                            weekCount?.fall_confirmed && (weekCount['fall_confirmed'] = timeZone(weekCount['fall_confirmed']))
                            weekCount?.fall_detected && (weekCount['fall_detected'] = timeZone(weekCount['fall_detected']))
                            fallData['week_count'] = [weekCount]
                        }
                        return ({ time, ...fallData })
                    }

                    return ({ time, value, status })
                })
                return formattedValue
            }
        }
        return (

            <div className='mt-5'>
                <div className='w-lg-75 mx-auto mb-2'>

                    {(tabIndex == 0) && (
                        <div className={`${styles.btnFilterCon}`}>

                            {name === 'FallDetection' &&
                                <Button
                                    sx={{ color: '#d8d8d8', fontSize: '0.5rem' }}
                                    className={`${styles.btnFilterIcon} mx-2`}
                                    onClick={handleFallDeviceList}
                                    endIcon={<ListIcon />}
                                >
                                    Device List
                                </Button>
                            }

                            <Button
                                endIcon={<SortIcon />}
                                sx={{ color: '#d8d8d8', fontSize: '0.5rem' }}
                                className={`${styles.btnSortIcon}`}
                                onClick={handleSortClick}
                            >
                                Sort
                            </Button>

                            {!(name === "BathRoom" || name === 'PillBox' || name === 'Shower' || name === 'Urgent Band') &&
                                <Button
                                    endIcon={<FilterIcon />}
                                    sx={{ color: '#d8d8d8', fontSize: '0.5rem' }}
                                    className={`${styles.btnFilterIcon} mx-2`}
                                    onClick={handleFilterClick}
                                >
                                    Filter
                                </Button>
                            }

                            <Menu
                                anchorEl={openFallDeviceList}
                                keepMounted
                                open={Boolean(openFallDeviceList)}
                                onClose={handleFallDeviceClose}
                                anchorOrigin={{ vertical: 'bottom' }}
                                className={`${styles.menuSortContainer}`}
                            >

                                {name === 'FallDetection' && deviceList[name]['deviceList']?.map(item =>
                                    <MenuItem
                                        onClick={() => handleGetFallData(item?.mac_id)}
                                        selected={fallDetectionData['device_selected'] === item?.mac_id}
                                    >
                                        <ListItemText primary={item?.name} />
                                    </MenuItem>)
                                }

                                <MenuItem
                                    onClick={() => handleGetFallData('all')}
                                    selected={fallDetectionData['device_selected'] === 'get_all'}

                                >
                                    <ListItemText primary="All" />
                                </MenuItem>

                            </Menu>

                            <Menu
                                anchorEl={openSortType}
                                keepMounted
                                open={Boolean(openSortType)}
                                onClose={handleSortClose}
                                anchorOrigin={{ vertical: 'bottom' }}
                                className={`${styles.menuSortContainer}`}
                            >
                                {name !== 'FallDetection' && <MenuItem onClick={() => handleSortToggle("Time")}>  <ListItemText primary="Time" /> </MenuItem>}

                                {name == 'FallDetection' &&
                                    <MenuItem
                                        onClick={() => sortFallDetectionData("Time")}
                                        selected={fallDetectionData['is_sorted']}
                                    >
                                        <ListItemText primary="Time" />
                                    </MenuItem>}

                                {!(name === 'BathRoom' || name === 'PillBox' || name === 'Shower' || name === 'Urgent Band' || name === 'FallDetection') &&
                                    <MenuItem onClick={() => handleSortToggle("Status")}>Status</MenuItem>
                                }
                            </Menu>
                            <Menu
                                anchorEl={openFilterType}
                                keepMounted
                                multiple
                                open={Boolean(openFilterType)}
                                disableRipple={true}
                                onClose={handleFilterClose}
                                anchorOrigin={{ vertical: 'bottom' }}
                                className={`${styles.menuFilterContainer}`}
                            >
                                {name !== 'FallDetection' &&
                                    <>
                                        <MenuItem
                                            onClick={() => handleToggle("Open", !filterData.Open)}
                                            selected={filterData.Open}
                                        >
                                            <ListItemText primary={`${(name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)') ? 'Vacant' : 'Open'}`} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => handleToggle("Closed", !filterData.Closed)}
                                            selected={filterData.Closed}
                                        >
                                            <ListItemText primary={`${(name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)') ? 'Occupied' : 'Closed'}`} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => handleToggle("All", !filterData.All)}
                                            selected={filterData.All}
                                        >
                                            <ListItemText primary="All" />
                                        </MenuItem>
                                    </>
                                }

                                {name === 'FallDetection' &&
                                    <>
                                        <MenuItem
                                            onClick={() => filterFallDetectionData("fall_detected")}
                                            selected={fallDetectionData['filter_selected'] === 'fall_detected'}
                                        >
                                            <ListItemText primary={'Detected'} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => filterFallDetectionData("fall_confirmed")}
                                            selected={fallDetectionData['filter_selected'] === 'fall_confirmed'}
                                        >
                                            <ListItemText primary={'Confirmed'} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => filterFallDetectionData("calling")}
                                            selected={fallDetectionData['filter_selected'] === 'calling'}
                                        >
                                            <ListItemText primary="Calling" />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => filterFallDetectionData("All")}
                                            selected={fallDetectionData['filter_selected'] === 'filter_all'}
                                        >
                                            <ListItemText primary="All" />
                                        </MenuItem>
                                    </>
                                }

                            </Menu>

                        </div>)}

                    <Box style={{ width: '100%' }}>

                        {name !== 'FallDetection' &&
                            <Tabs
                                value={currentSelectedSensor}
                                onChange={''}
                                indicatorColor='primary'
                                variant="scrollable"
                                scrollButtons={'auto'}
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                style={{ borderBottom: '1px solid #61677A' }}
                            >
                                {
                                    deviceList[sensorNameMaper(name)]['deviceList']?.map((item, ind) => (
                                        <Tab
                                            label={
                                                <div className='d-flex align-items-center'>
                                                    {item['name']}
                                                    {ind === currentSelectedSensor &&
                                                        <MUITooltip
                                                            title={
                                                                <div>
                                                                    <span className='d-flex align-items-center'>
                                                                        <img src={battery} alt="battery" style={{ width: '1.5rem', marginRight: '.5rem' }} />
                                                                        <span>
                                                                            {data['last_battery'] ? data['last_battery'] + "%" : "--:--"}
                                                                            <br />
                                                                            {/* This is for Local time */}
                                                                            {data['last_update'] ? timeZone(data['last_update']) : "--:--"}

                                                                            {/* {data['last_update'] ? moment(data['last_update']).parseZone().format('DD MMM YYYY hh:mm A') + " " + (tz?.tz_abbreviation || '') : "--:--"} */}
                                                                        </span>
                                                                    </span>

                                                                </div>}
                                                            placement='top'
                                                            arrow >
                                                            <img style={{ cursor: 'pointer', height: '1rem', width: '1rem', marginLeft: '.5rem' }} src={infoIcon} alt='infoIcon' />
                                                        </MUITooltip>
                                                    }
                                                </div>
                                            }
                                            id={`tab 1`}
                                            className={classes.selectedTab}
                                            onClick={() => handleCurrentSelectedSensor(ind)}
                                        />
                                    ))
                                }
                            </Tabs>
                        }
                    </Box>
                </div>
                <TableContainer>
                    {loading ?
                        <div className='text-center mt-5' >
                            <CircularProgress />
                        </div>
                        :
                        <Table stickyHeader aria-label="Sensor table" style={{ tableLayout: 'fixed', minWidth: "680px" }}>
                            <TableHead>
                                <TableRow>
                                    {timielineMapper[tabIndex] !== 'day' && name === 'FallDetection' && <TableCell align="center" variant='head' style={{ width: "100%" }}>Day</TableCell>}
                                    <TableCell align="center" variant='head'>{name === "FallDetection" ? "Device" : timielineMapper[tabIndex] === 'day' ? 'Time' : timielineMapper[tabIndex] === 'weekly' ? 'Day' : 'Date'}</TableCell>
                                    {timielineMapper[tabIndex] === 'day' && (name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)' || name === 'BathRoom') && <TableCell align="center" variant='head'>Duration</TableCell>}
                                    {name === 'FallDetection' && <TableCell align="center" style={{ width: '150%' }} variant='head'>Process</TableCell>}
                                    {name != 'BathRoom' && <TableCell align="center" variant='head'>{name === 'Urgent Band' ? 'SOS Button' : (name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)') ? (timielineMapper[tabIndex] === 'day' ? 'Status' : 'Occupied') : (name === 'FallDetection') ? 'CEN Status' : name}</TableCell>}
                                    {timielineMapper[tabIndex] != 'day' && (name === 'BathRoom') && <TableCell align="center" variant='head'>Duration</TableCell>}
                                    {timielineMapper[tabIndex] != 'day' && (name === 'BathRoom') && <TableCell align="center" variant='head'>Status</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tableFiltedData?.length > 0 ? (
                                        (rowsPerPage > 0
                                            ? formattedData(tableFiltedData)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : formattedData(tableFiltedData)
                                        ).map((item) => {
                                            return (
                                                !((name === 'PillBox' || name === 'Shower') && item?.value === false) &&
                                                (<TableRow>
                                                    <TableCell align="center" variant='body'>{name === "FallDetection" ? timielineMapper[tabIndex] === "day" ? item.device_name : item.time : item?.time !== 'Invalid date' ? item?.time : ''}</TableCell>
                                                    {timielineMapper[tabIndex] === 'day' && (name === 'Sleep Belt (Bed)' || name === 'Sleep Belt (Chair / Couch)' || name === 'BathRoom') && <TableCell align="center" variant='body'>{item?.duration || '-'}</TableCell>}
                                                    {timielineMapper[tabIndex] != 'day' && (name === 'BathRoom') && <TableCell align="center" variant="body">{item?.value === undefined ? '-' : item?.value}</TableCell>}
                                                    {(name != 'BathRoom') &&
                                                        <TableCell align="center" variant='body'>
                                                            {name === "FallDetection" ?
                                                                <>
                                                                    {timielineMapper[tabIndex] === 'day' && <FallDetectionStepper data={item} />}
                                                                    {timielineMapper[tabIndex] !== 'day' && item?.week_count?.length === 0 && '--'}
                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '70px' }}>
                                                                        {timielineMapper[tabIndex] !== 'day' && item?.week_count?.length !== 0 && item?.week_count?.map(n => (<div>{n.device_name}</div>))}
                                                                    </div>
                                                                </>
                                                                :
                                                                item?.value || '-'}
                                                        </TableCell>
                                                    }
                                                    {timielineMapper[tabIndex] !== 'day' && (name === 'FallDetection') &&
                                                        <TableCell align="center" variant='body'>
                                                            {item?.week_count?.length === 0 && '--'}
                                                            {item?.week_count?.map(n => (<FallDetectionStepper data={n} />))}
                                                        </TableCell>
                                                    }
                                                    {timielineMapper[tabIndex] !== 'day' && (name === 'FallDetection') && <TableCell align="center" variant="body">
                                                        {item?.week_count?.length === 0 && '--'}
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '90px' }}>
                                                            {item?.week_count?.map(n => (n?.calling ? <div> {`CEN SENT (${n?.count})`} </div> : "--"))}
                                                        </div>
                                                    </TableCell>}
                                                    {timielineMapper[tabIndex] === 'day' && (name === 'FallDetection') && <TableCell align="center" variant="body">{item?.calling ? 'CEN SENT' : "--"}</TableCell>}
                                                    {timielineMapper[tabIndex] != 'day' && (name === 'BathRoom') && <TableCell align="center" variant="body">{item?.status}</TableCell>}
                                                </TableRow>)
                                            )
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center" variant='body'>No Data</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            {
                                tableFiltedData?.length > 0 && tabIndex !== 1 && <TableFooter>
                                    <TableRow className={styles.sensorTablePagination}>
                                        <TablePagination
                                            color='#00B89F'
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={tableFiltedData?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            className='text-primary pagination'
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page', style: { color: '#00b89F' } },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            }
                        </Table>
                    }

                </TableContainer>

            </div >

        )
    }

    const getChart = (name) => {

        const GetTabData = () => {

            switch (name) {
                case "Heart Rate":
                    return heartRate
                case "Blood Pressure":
                    return pressure
                case "Sleep":
                    return sleep
                case "Steps":
                    return step
                case "Calories":
                    return calorie
                case "Blood Oxygen":
                    return oxygen
                default:
                    return null
            }
        }



        switch (tabIndex) {
            case 0:
                return isSensor ? <WeeklyGraph isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} day={true} />
                    : <DailyGraph isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} tabnum={tabIndex} basedata={GetTabData} graphScale={graphScale} />;
            case 1:
                return <WeeklyGraph isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} isSleepData={isSleepData} tabnum={tabIndex} basedata={GetTabData} graphScale={graphScale} />;
            case 2:
                return <WeeklyGraph isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} isSleepData={isSleepData} tabnum={tabIndex} basedata={GetTabData} graphScale={graphScale} />;
            case 3:
                return isSensor ? <AnnualGraph data={data} /> : <WeeklyGraph isAnnualData={true} isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} isSleepData={isSleepData} tabnum={tabIndex} basedata={GetTabData} graphScale={graphScale} />;
            default:
                return <DailyGraph isBloodPressure={isBloodPressure} data={data} isSensor={isSensor} tabnum={tabIndex} basedata={GetTabData} graphScale={graphScale} />;
        }
    }

    if (!data) {
        return <div className={`${styles.chartSection} p-3 flex-grow-1 h-100 text-center`}> <p className='txt-primary txt-xl'>Graph is not applicable for this Sensor</p></div>
    }
    return (<div className={`${styles.chartSection} ${styles[classMapper[name]]} p-3 flex-grow-1 h-100`}>
        <div className='d-flex justify-content-between align-items-center mb-3 flex-lg-row flex-column'>
            <div>
                <p className='txt-primary txt-xxl my-0'>
                    {name === "FallDetection" ? "Fall Detection" : name}
                </p>
                <div className='d-flex align-items-center'>
                    {isSensorTable ? (<p className={`txt-primary ${styles.dateTxt} mt-2`}>{momentTZ.tz(selectedDate, 'GMT').format('MMM DD, YYYY dddd') + " " + (tz?.tz_abbreviation || '')}</p>) : null}
                    {isSensorTable && <DatePicker className={`mt-3`} inputProps={{ className: `txt-md`, name: 'date' }} showOnlyCalenderIcon={true} value={selectedDate || null} onChange={handleDateChange} />}
                </div>
            </div>
            <div className='d-flex flex-column align-items-end'>
                {

                    <Tabs value={tabIndex} onChange={handleTabChange} centered={true} indicatorColor='primary' className='btn-tabs indicator-none'>
                        <Tab label='Daily' id={`tab 1`} />
                        <Tab label='Weekly' id={`tab 2`} />
                        <Tab label='Monthly' id={`tab 3`} />
                        {!isSensorTable && <Tab label='Annually' id={`tab 3`} />}
                    </Tabs>
                }
            </div>
        </div>
        {isSensorTable ? getTable() : getChart(name)}
    </div>)
}

export default TabContent