import styles from './header.module.scss';
import logo from 'assets/images/background/logo.png'
import avatar from 'assets/images/icon/default.svg';
import searchIcon from 'assets/images/icon/search.svg';
import user from 'assets/images/icon/user.svg';
import logout from 'assets/images/icon/logout.svg';
import { TextField, Menu, MenuItem, Badge, ConfirmDialog, ChatPopover } from 'components';
import { useEffect, useState, useContext } from 'react';
import AuthServices from 'api/services/auth-services';
import { ReactComponent as Bell } from 'assets/images/icon/bell.svg'
import { GlobalContext, actions } from 'context';
import EditMember from 'app/dashboard/edit-member/edit-member';
import badge from 'assets/images/icon/badge.svg';
import { differenceInCalendarDays, format } from 'date-fns';
import { onMessageListener } from 'utils/firebase';

const Header = () => {

    const { state: { selectedMasterData: { m_obj_id } }, dispatch } = useContext(GlobalContext);
    const [profileData, setProfile] = useState({});
    const [groupedChats, setGroupedChats] = useState([]);
    const { profile, executive } = profileData || {};
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const { permission } = profileData || {}
    const { chat } = permission || {}
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    useEffect(() => {
        if (m_obj_id) {
            getProfile()
            getChats()
        }

    }, [openDialog, m_obj_id])

    useEffect(() => {
        if (m_obj_id) {
            if (chat) {
                getChats()
            }
            const interval = setInterval(() => chat && getChats(), 30000)
            return () => {
                clearInterval(interval);
            }
        }
    }, [chat, m_obj_id])

    const getProfile = async () => {
        if (!m_obj_id) return
        try {
            const { member, countries } = await AuthServices.getProfileData(m_obj_id);

            storeHandler(actions.SET_USER_DETAILS, { ...member });
            storeHandler(actions.SET_COUNTRY, [...countries]);

            setProfile({ ...member, gender: member.sex })


        } catch (err) {
            const { data } = err || {}
            const { message } = data || "member not found";
            setShowSnackBar(true);
            setSnackBarContent({ message, class: "error" })
            storeHandler(actions.SHOW_LOADER, false);
        }

    }

    const getChats = async () => {
        if (!m_obj_id) return
        try {
            const { chats } = await AuthServices.getChats(m_obj_id);
            const groupByDate = chats?.reduce((group, product) => {
                let msgDate = new Date(product.date_time)
                const currentDate = new Date()
                const diff = differenceInCalendarDays(currentDate, msgDate)
                const date = diff === 0 ? "Today" : diff === 1 ? "Yesterday" : format(msgDate, "MMM dd, yyyy")
                group[date] = group[date] ?? [];
                group[date].push(product);
                return group;
            }, {});
            setGroupedChats(groupByDate)

        } catch (err) {
            console.log(err, 'chat not found')
        }

    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const logOut = () => {
        localStorage.clear();
        window.location.reload()
    }

    const handleClose = () => {
        setAnchorEl2(null);
        setOpenDialog(false);
    };

    const onConfirm = () => {
        logOut()
        setOpenPopup(false);
    }

    return (
        <div className={`${styles.headerWrapper} px-sm-3 py-sm-2 p-2 d-flex justify-content-between flex-wrap`}>
            <div className='d-flex flex-column'>
                <img src={logo} alt="logo" className={`${styles.appLogo} mb-1`} />
                <span className='txt-primary ml-2'>Members Portal</span>
            </div>
            <div className='d-flex align-items-center'>
                <TextField label="Search" variant="filled" fullWidth className={'dark mr-4 d-none'} size="small" endAdornment={searchIcon} />
                <Badge badgeContent={null} color="primary" className='mr-4 d-none'>
                    <Bell className={styles.notification} />
                </Badge>
                <div className={`${styles.avatar} cursor`}>
                    <img src={profile || avatar} alt="dashboard-icon" onClick={(event) => setAnchorEl2(event.currentTarget)} />
                    {executive && <div className={`${styles.badge} d-flex align-items-center justify-content-center`}>
                        <img src={badge} alt="Profile Image" />
                    </div>}
                </div>
                <Menu
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                    className='dark'
                >
                    <MenuItem className='logout cursor-default'> <img src={user} width="16px" alt="user" /><span className='txt-md ml-2 capitalize'>{`${profileData.fname} ${profileData.lname}`}</span></MenuItem>
                    <MenuItem onClick={() => setOpenDialog(true)} className='logout'> <img src={user} width="16px" alt="user" /><span className='txt-md ml-2 capitalize'>Edit Profile</span></MenuItem>
                    <MenuItem onClick={() => setOpenPopup(true)} className='logout'> <img src={logout} width="16px" alt="logout" /><span className='txt-md ml-2'>Logout</span></MenuItem>
                </Menu>
            </div>
            {chat && <ChatPopover id={profileData?.id} refreshChat={getChats} groupedChats={groupedChats} m_obj_id={m_obj_id} />}
            {openDialog && <EditMember open={openDialog} handleClose={handleClose} memberInfo={profileData} isLoggedInUser={true} m_obj_id={m_obj_id} />}
            <ConfirmDialog isPrompt={false} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={`Are you Sure you want to logout?`} />
        </div>
    )
}

export default Header