const backEndValidations = {
    'Invalid Email Id !': { email: 'Invalid email id', forgetPassemail: 'Invalid email id' },
    'Invalid password!': { password: 'Incorrect Password' },
    'member with this Email not found': { forgetPassemail: 'Invalid email id' },
    'Member OTP verification failed': { otp: 'Incorrect OTP' }
}

const errorMessages = {
    email: 'please Enter the email id',
    password: 'please Enter the Password',
    otp: 'Icorrect OTP'
}

const getErrorMessage = (isInvalid, field, value, fromError) => {
    if (!isInvalid) {
        return
    }
    return !value ? errorMessages[field] : fromError && backEndValidations[fromError][field]
}


export { errorMessages, getErrorMessage };