import { Avatar, Typography, makeStyles } from '@material-ui/core'
import { Box, Tab, Tabs } from 'components'
import React, { useState } from 'react'
import styles from './Seniordetailcard.scss';

const TabCard = ({ senior }) => {
    const { profile, senior_id, room_no, name, age, gender } = senior || {}

    return (
        <>
            <Box direction="row" className={`d-flex  txt-primary p-2 g-5`} >
                <Box alignItems="center" justifyContent="center">
                    <Box>
                        <Avatar
                            
                            style={{ width: '4rem', height: '4rem', marginRight: '0rem', marginBottom: "0.2rem", marginX: 'auto',justifyContent:'center' }}
                            src={profile}
                            alt=""
                        />
                    </Box>
                    <Box
                        sx={{ fontSize: '0.7rem', color: '#9c9c9c', marginTop: "0.2rem" }}
                    >{`ID: ${senior_id}`}</Box>


                </Box>
                <Box alignItems="center" justifyContent="center" marginX='8px'>
                    <Box

                        sx={{
                            textAlign: 'start',
                            fontSize: '1rem',
                            color: 'primary.main',
                            // marginBottom: '0.25rem',
                            hyphens: 'manual',
                        }}
                    >
                        {name}
                    </Box>

                    <Box
                        sx={{
                            textAlign: 'start',
                            fontSize: '0.75rem',
                            color: '#9c9c9c',
                            // marginBottom: '0.25rem',
                        }}
                    >
                        {`Age ${age || ''}, ${gender}`}
                    </Box>

                    {!room_no ? " " : <Box sx={{ textAlign: 'start', fontSize: '0.75rem', color: '#9c9c9c', marginTop: "0rem" }}>
                        Room No : {room_no}
                    </Box>}

                </Box>
            </Box>
        </>
    )

}

export default function Seniordetailcard({ list }) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <Box sx={{ width: '100%', justifyContent:'center' }}>
            <Tabs
                className={styles. style}
                onChange={handleChange}
                variant="scrollable"
                style={{color:"white" }}
                aria-label="visible arrows tabs example"
                
            >
                {list?.map((item, index) => (
                    <Tab
                        className='p-2 mx-2'
                        key={index}
                        disableRipple
                        style={{ background: '#24292d', borderRadius: '10px', }}
                        label={<TabCard senior={item} />}
                    />
                ))}
            </Tabs>
        </Box>
    )
}