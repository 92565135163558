import { useState, useContext, useEffect } from 'react';
import edit from 'assets/images/icon/edit.svg';
import infoIcon from 'assets/images/icon/info-icon.svg';
import { Tooltip } from '@material-ui/core';
import styles from '../dashboard.module.scss';
import { getIcon } from '../icons';
import SeniorInfoPopup from './senior-info-popup';
import EditSenior from './edit-senior-popup';
import { GlobalContext, actions } from 'context';
import { ConfirmDialog } from 'components';
import HealthTab from '../health-tab/health-tab';


const SeniorInfo = ({ seniorData, index, tabIndex, refreshSeniorList }) => {

    const { state: { userData, refresh_loader }, dispatch } = useContext(GlobalContext);
    const { executive } = userData || {};

    const { age, gender, room_no } = seniorData;
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [editSeniorDialog, setEditSeniorDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [refreshLoader, setRefreshLoader] = useState(false);
    const storeHandler = (type, payload) => dispatch({ type, payload })
    const handleClose = (event, reason, refrehSenior) => {
        setOpenInfoDialog(false);
        setEditSeniorDialog(false)
        if (refrehSenior) {
            setOpenPopup(true)
            refreshSeniorList()
        }
    }

    const editProfile = () => {
        setEditSeniorDialog(true)
    }
    const profileInfo = () => {
        setOpenInfoDialog(true)
    }
    const onConfirm = () => {
        setOpenPopup(false);
        window.location.reload()
    }

    const refreshHealthTab = () => {
        storeHandler(actions.REFRESH_SENIOR, true);
        storeHandler(actions.REFRESH_LOADER, true);
    }

    return (
        <div className={`${index !== tabIndex ? 'd-none' : 'd-flex align-items-center mt-1'}`}>
            <span className='pr-3 cursor-normal'>{room_no && <span >Room No {room_no}, </span>}Age {age}, {gender}</span>
            {executive && <Tooltip title="Click to edit senior details"><img src={edit} alt="edit" className={`cursor ${styles.editIcon} mr-2`} onClick={editProfile} /></Tooltip>}
            <Tooltip title='Click to get the senior info'><img src={infoIcon} alt="info" className={`cursor ${styles.infoIcon}`} onClick={profileInfo} /></Tooltip>
            {openInfoDialog && <SeniorInfoPopup open={openInfoDialog} handleClose={handleClose} seniorData={seniorData} />}
            {editSeniorDialog && <EditSenior open={editSeniorDialog} handleClose={handleClose} seniorData={seniorData} />}
            <Tooltip title={"Refresh For Sensor Details"}>
                <div style={
                    {
                        height: '2.0rem',
                        width: '2.0rem',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0.6rem',
                        color: '#afafaf'
                    }
                }>
                    {getIcon('Refresh', '#00b89f', ` curosor  ${refresh_loader && styles.refreshLoader} mr-3`, refreshHealthTab)}
                </div>
            </Tooltip>

            <ConfirmDialog isPrompt={true} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={`Senior profile updated successfully`} />
        </div>
    )
}

export default SeniorInfo;
