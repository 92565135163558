import { useState, useContext } from 'react';
import { Box, TextField, Button, Snackbar } from 'components';
import { Link } from 'react-router-dom';
import BackIcon from 'assets/images/icon/back-icon.svg'
import ChangePassword from 'app/change-password/change-password';
import OtpInput from 'react-otp-input';
import sendIcon from 'assets/images/icon/mail-icon.svg'

import AuthServices from 'api/services/auth-services';

import { GlobalContext, actions } from 'context';

import { getErrorMessage } from 'constants/constants'
import { isEmailValid } from 'utils/validator';


const OTP_SENT = 'OTP sent to email!';
const OTP_VERIFIED = 'Member OTP verified!';

const ForgotPassword = () => {

    const { dispatch } = useContext(GlobalContext);

    const [showVerifyOtp, setShowVerifyOtp] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');

    const [fromError, setFormError] = useState();
    const [invaildEmailId, setInvalidEmailId] = useState(false);

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    const generateOtp = async (event) => {
        event.preventDefault()
        const formData = new FormData()
        formData.append('email', email)
        storeHandler(actions.SHOW_LOADER, true)
        setInvalidEmailId(false)
        setShowSnackBar(false)

        try {
            const { message } = await AuthServices.getOtp(formData);
            if (message === OTP_SENT) {
                setShowVerifyOtp(true);
                const snackBardata = { message: 'OTP sent successfully', class: 'success' }
                setShowSnackBar(true)
                setSnackBarContent({ ...snackBardata })

            }
            storeHandler(actions.SHOW_LOADER, false)

        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
            const { data } = err || {};
            const { message } = data || {};
            setFormError(message);

            setInvalidEmailId(true)
        }
    }

    const verifyOtp = async () => {
        const formData = new FormData();
        formData.append('email', email)
        formData.append('otp', otp)
        storeHandler(actions.SHOW_LOADER, true)

        try {
            const { message } = await AuthServices.verifyOtp(formData);
            if (message === OTP_VERIFIED) {
                setShowChangePassword(true)
            }
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
            const { data } = err || {};
            const { message } = data || {};
            const snackBardata = { message: getErrorMessage(true, 'otp', true, message), class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...snackBardata })
        }
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })
    
    if (showChangePassword) {
        return <ChangePassword email={email} />
    }

    return (
        <>
            {!showVerifyOtp && <div>
                <Box textAlign="center" className='txt-lg txt-bold mb-3 d-flex justify-content-between'><Link to="/login"><img src={BackIcon} alt="back" /></Link><span className='flex-grow-1  txt-primary text-center'>Forgot Password</span></Box>
                <p className='txt-grey-drk text-center txt-smd'>Enter registered email to get OTP</p>
                <form onSubmit={generateOtp}>
                    <TextField label="Email" variant="filled" fullWidth className={'mb-3'} type='text'
                        onChange={(event) => { setEmail(event.target.value); }}
                        helperText={invaildEmailId && fromError} />

                    <Button type='submit' variant="contained" color="primary" className={'btn-lg'} disabled={!isEmailValid(email)}>
                        <img src={sendIcon} alt="send" className='mr-3' /> Confirm
                    </Button>
                </form>
            </div>}
            {showVerifyOtp && <div className='position-relative txt-primary'>

                <Box textAlign="center" className='txt-lg txt-bold mb-3 txt-primary d-flex justify-content-between'><Link to="/login"><img src={BackIcon} alt="back" /></Link> <span className='flex-grow-1  txt-primary text-center'>Verify Phone OTP</span> </Box>
                <Box textAlign="center" className='txt-md my-3 txt-grey-drk'>Please Check your Phone for a six-digit security code and enter below</Box>
                <form>
                    <OtpInput
                        value={otp}
                        onChange={(val) => setOtp(val)}
                        numInputs={6}
                        separator={<span className='seperator'></span>}
                        isInputNum={true}
                        isInputSecure={true}
                        inputStyle={'otp mr-3'}
                        containerStyle={'justify-content-center mb-3'}
                        focusStyle={'otp-focus'}
                    />
                    <Box textAlign="center" className='txt-md mb-3 txt-grey-drk'>Didn't receive a code? <span className='txt-bold cursor' onClick={generateOtp}>Resend code</span></Box>
                    <Button variant="contained" color="primary" className={'btn-lg'} onClick={verifyOtp} disabled={otp.length < 6}>
                        Verify
                    </Button>
                </form>
            </div>}
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </>
    )
}

export default ForgotPassword;