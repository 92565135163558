import { useContext, useState } from 'react';
import styles from './login.module.scss';
import { TextField, Button, Snackbar, Box } from 'components';
import { Link } from 'react-router-dom';
import AuthServices from 'api/services/auth-services';

import logo from 'assets/images/background/logo.png'
import sendIcon from 'assets/images/icon/mail-icon.svg'

import { GlobalContext, actions } from 'context';
import { getErrorMessage } from 'constants/constants'
import { getCurrentToken } from 'utils/firebase';
import { Typography } from '@material-ui/core';
import { isEmailValid } from 'utils/validator';

const INVALID_EMAIL = 'email number is invalid';
const INVALID_PASSWORD = 'password is mismatch';

const Notification = {
    email: { message: 'Please enter a valid mobile number', class: 'error' },
    wrongemail: { message: 'Check the Email ID', class: 'error' },
    wrongPassword: { message: 'The password was found incorrect !!', class: 'error' },
    error: { message: 'We are facing some issues, Please try later!!', class: 'error' }
}

const Login = () => {

    const { dispatch } = useContext(GlobalContext);
    const [credientials, setCredientials] = useState({});

    const inputFields = ['email', 'password'];
    const [invalidForm, setInvalidForm] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [fromError, setFormError] = useState();
    const [wrongemail, setWrongemail] = useState();
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    const snackbarNotification = (data) => {
        setSnackBarContent(data)
        setShowSnackBar(true)
    }
    const storeHandler = (type, payload) => dispatch({ type, payload });

    const validate = (event) => {
        event.preventDefault()
        let invalidFields = [];
        inputFields.forEach(item => {
            if (!credientials[item]) {
                invalidFields.push(item)
            }
        })
        if (invalidFields.length) {
            setInvalidForm(true)
        } else {
            logIn();
            setInvalidForm(false)
        }
    }

    const logIn = async () => {
        const formData = new FormData();
        for (let key in credientials) {
            formData.append(key, credientials[key])
        }
        const token = await getCurrentToken()
        formData.append("device_token", token)
        storeHandler(actions.SHOW_LOADER, true)

        try {
            const { access_token, refresh_token, message } = await AuthServices.login(formData);
            localStorage.setItem('authToken', access_token);
            localStorage.setItem('refreshToken', refresh_token);
            storeHandler(actions.SHOW_LOADER, false)

            const data = { message, class: 'success' }
            setShowSnackBar(true);
            setSnackBarContent({ ...data });
            setTimeout(() => {
                storeHandler(actions.LOG_IN, true);
            }, 1000)
            // storeHandler(actions.MEMBER_DETAILS, { acc_id: master_account_id, obj_id: master_obj_id });

        } catch (err) {
            const { data } = err || {};
            const { message } = data || {};
            setFormError(message);
            setInvalidForm(false)
            if (message === INVALID_EMAIL) {
                setWrongemail('Check the Email ID')
                snackbarNotification(Notification.wrongemail)
            }
            else if (message === INVALID_PASSWORD) {
                snackbarNotification(Notification.wrongPassword)
            }
            else {
                snackbarNotification({
                    message,
                    class: 'error'
                });
            }
            storeHandler(actions.SHOW_LOADER, false);
        }

    }

    const loginInfo = (key, value) => {
        if (key === 'email' && value.length > 50) return
        const data = { ...credientials, [key]: value }
        setCredientials({ ...data })
        invalidForm && setInvalidForm(false)
    }
    const emailValidator = async (event) => {
        const value = event.target.value;
        setInvalidEmail(!isEmailValid(value))
    }
    return (
        <div className={`${styles.loginContainer} text-center`}>
            <Box
                style={{
                    position: 'relative',
                    width: '17.5rem',
                    margin: '0 auto 2rem auto',
                }}>
                <img src={logo} alt="logo" className='w-100' />
                <Typography className='txt-primary'
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        letterSpacing: '0.46rem',
                    }}
                >
                    Family Portal
                </Typography>

            </Box>

            <form onSubmit={validate}>
                <TextField label="Email" variant="filled" fullWidth className={'mb-3'} type='email' value={credientials?.email || ''}
                    onChange={(event) => loginInfo('email', event.target.value)} onBlur={emailValidator} helperText={getErrorMessage(invalidForm, 'email', credientials?.email, fromError) || invalidEmail && 'Enter valid email id'} />

                <TextField label="Password" variant="filled" fullWidth className={'mb-3'} passwordfield={true} value={credientials?.password || ''}
                    onChange={(event) => loginInfo('password', event.target.value)} helperText={getErrorMessage(invalidForm, 'password', credientials?.password, fromError)} />

                <Button type='submit' variant="contained" color="primary" className={'btn-lg txt-lg mb-3'} >
                    <img src={sendIcon} alt="send" className='mr-3' /> Login
                </Button>
            </form>
            <Link className='txt-primary txt-xmd txt-light' to="/forgot-password">Forgot Password</Link>
            <Snackbar open={showSnackBar}
                message={snackbarContent?.message || ''}
                className={snackbarContent?.class || ''}
                autoHideDuration={4000}
                closeSnackBar={setShowSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}

            />

        </div>
    )
}

export default Login