import styles from './prelogin.module.scss';
import logo from 'assets/images/background/logo.png'

const PreLogin = ({ children }) => {
    return (
        <div className={`${styles.loginWrapper} d-flex`}>
            <div className={`${styles.loginBg} ${styles.flexAuto} d-lg-flex d-none`}></div>
            <div className={`${styles.flexAuto} d-flex flex-column  p-3`}>
                <div className='d-flex align-items-center justify-content-center flex-grow-1'>
                    <div className={`${styles.container} p-4 mt-5`}>
                        {children}
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center pb-4'>
                    <span className='mr-3 txt-bold txt-primary-drk'>Powered by</span> <img src={logo} alt="logo" className={`${styles.logo}`} />
                </div>
            </div>
        </div>
    )
}

export default PreLogin