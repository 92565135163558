import axios from 'axios';


const httpClient = axios.create({
  baseURL: process.env.REACT_APP_FAMILY_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  },
});

const onSuccess = (response) => Promise.resolve(response);
const onError = async ({ response }) => {
  const { data } = response || {};
  const { message, user } = data;
  if (message === 'Token has expired' || response.status === 422 || response.status === 401 || message === 'Master ID not found') {
    localStorage.clear()
    window.location.reload();
    console.log(data)
  }
  else if (response.status === 404 && !user) {
    response.data.message = "the user account is no longer available. Contact the administrator"
    localStorage.clear()
    setTimeout(() => window.location.href = "/login", 2000)
  }
  return Promise.reject(response)
};

httpClient.interceptors.request.use((req) => {
  if (req.url.indexOf('/video/delete') > -1) {
    req.headers['Content-Type'] = 'application/json'
  }

  if (req.url.indexOf('/get/member') === 0) {
    req.headers['Content-Type'] = 'application/json'
  }

  if (req.url === '/refresh/token') {
    req.headers['Authorization'] = `Bearer ${localStorage.getItem('refreshToken')}`
  } else {
    req.headers['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`
  }
  return req
}
);
httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
