import user from 'assets/images/icon/default.svg';
import styles from '../dashboard.module.scss';
import SeniorInfo from './senior-info';


const TabLabel = ({ seniorData, refreshSeniorList, tabIndex, index }) => {
    const { fname, profile, lname } = seniorData
    return (
        <>
            <div className={`d-flex align-items-center p-2 ${styles.tabLabel} w-100 ${index !== tabIndex ? '' : styles.tabBorder}`}>
                <img src={profile || user} alt="user" className={`${styles.userIcon} mr-3`} />
                <span className='txt-xlg'>{fname} {lname}</span>
            </div>
            <SeniorInfo seniorData={seniorData} index={index} refreshSeniorList={refreshSeniorList} tabIndex={tabIndex} />
        </>

    )
}

export default TabLabel;