import {
    LOG_IN,
    SHOW_LOADER,
    MEMBER_DETAILS,
    SET_USER_DETAILS,
    SET_RELATIONS,
    SET_COUNTRY,
    REFRESH_SENIOR,
    REFRESH_LOADER,
    ALL_MASTER_DETAILS,
    ALL_MASTER_LIST,
    SELECTED_SENIOR,
    SELECTED_SENIOR_DATA,
} from './actions';

const initialState = {
    masterData: { //DW
        acc_id: null,
        obj_id: null
    },
    allMasterDetails: [],
    selectedSenior: 0,
    refresh_senior: false,
    refresh_loader: false,

    // ............
    allMasterList: [],
    selectedMasterData: {
        m_acc_id: null,
        m_obj_id: null
    },
    isLoggedIn: false, //W
    showLoader: false, //W
    userData: {}, //W
    relationList: [], //W
    countryList: [], //W


};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case LOG_IN: {
            return {
                ...state,
                isLoggedIn: payload,
            }
        }
        case SHOW_LOADER: {
            return {
                ...state,
                showLoader: payload
            }
        }
        case MEMBER_DETAILS: {
            return {
                ...state,
                masterData: { ...payload }
            }
        }
        case ALL_MASTER_DETAILS:
            return {
                ...state,
                allMasterDetails: [...payload]
            }

        case ALL_MASTER_LIST:
            return {
                ...state,
                allMasterList: [...payload]
            }

        case SELECTED_SENIOR_DATA:
            return {
                ...state,
                selectedMasterData: { ...payload }
            }

        case SELECTED_SENIOR:
            return {
                ...state,
                selectedSenior: Number(payload) || 0
            }
        case SET_USER_DETAILS: {
            return {
                ...state,
                userData: { ...payload }
            }
        }
        case SET_RELATIONS: {
            return {
                ...state,
                relationList: [...payload]
            }
        }
        case SET_COUNTRY: {
            return {
                ...state,
                countryList: [...payload]
            }
        }
        case REFRESH_SENIOR: {
            return {
                ...state,
                refresh_senior: payload
            }
        }
        case REFRESH_LOADER: {
            return {
                ...state,
                refresh_loader: payload
            }
        }
        default:
            throw new Error();

    }
};

export { reducer, initialState }