// Login APIs
export const LOGIN = '/login/member';
export const GET_OTP = '/forget/member';
export const VERIFY_OTP = '/verifyotp/member';
export const RESET_PASSWORD = '/reset/member';

// Profile APIs
export const GET_PROFILE = '/get/profile/member';
export const EDIT_PROFILE = '/edit/profile/member';
export const DELETE_PROFILE_IMG = '/profile_delete/'


// Senior APIs

export const GET_SENIORS = '/get/seniors/member';
export const GET_SEN = '/get/member/master_list'
export const GET_DASHBOARD = '/dashboard';
export const GET_CHARTS = '/graph/health_status';
export const GET_SENSOR_TABLE = "/table/sensors";
export const GET_NOTIFICATIONS = '/notification/senior';
export const EDIT_SENIOR = '/edit/senior';

// Members APIs

export const GET_MEMBERS = '/get/members/member';
export const ADD_MEMBERS = '/add/member';
export const EDIT_MEMBERS = '/edit/member';
export const DELETE_MEMBER = '/delete/member/';
export const MEMBER_EMAIL_EXIST = '/get/member';
export const MEMBER_SENIOR ='/get/member/seniors'
// General APIs

export const GET_ADDRESS = '/zipcode'
export const GET_RELATIONS = '/admin/relations'

// Chat APIs

export const GET_CHATS = '/get_chats/member'
export const SEND_CHATS = '/send_chat/member'

// Video APIs

export const GET_VIDEO = '/get/video'
export const VIDEO_FEED = '/video_feed?url='